<footer class="t_size">
    <div class="rec_section rec_section_4">
        <div class="row row-top">
            <div class="col-lg-9 no-padding">
                <div class="row departments">
                    <ng-container *ngIf="currentLang=='vi'">
                        <div class="col-lg-6 brd_top brd_right rec_box">
                            <div class="info_box inside_box">
                                <h2>{{'APP.vietteljsc' |translate}}</h2>
                                <div class="t_sarabun">
                                    <div><strong>"PHÁT HUY TRUYỀN THỐNG - SÁNG TẠO TƯƠNG LAI"</strong></div>
                                    <div>Website thông tin chính thức của Công ty cổ phần Tư vấn và Dịch
                                        vụ Viettel - một thành viên của Tập đoàn Công nghiệp - Viễn thông
                                        Quân đội (Viettel).</div>
                                    <div>
                                        <div>
                                            <span class="title t_color_2 t_bold">Cơ quan chủ quản: </span>
                                            <span>Công ty cổ phần Tư vấn và Dịch vụ Viettel</span>
                                        </div>
                                        <div>
                                            <span class="title t_color_2">Mã số doanh nghiệp: </span>
                                            <span>0100109106-075 do Sở Kế hoạch và Đầu tư
                                                Thành phố Hà Nội cấp lần đầu ngày 23/03/2010.</span>
                                        </div>

                                        <div>
                                            <span class="title t_color_2">Chịu trách nhiệm nội dung: </span>
                                            <span>Ông Nguyễn Đăng Hùng</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentLang=='en'">
                        <div class="col-lg-6 brd_top brd_right rec_box">
                            <div class="info_box inside_box">
                                <h2>{{'APP.vietteljsc' |translate}}</h2>
                                <div class="t_sarabun">
                                    <div><strong>"PROMOTING TRADITION - CREATING THE FUTURE"</strong></div>
                                    <div>The official website of Viettel Consultancy and Services Joint Stock Company -
                                        a
                                        member of Viettel Group.</div>
                                    <div>
                                        <div>
                                            <span class="title t_color_2 t_bold">Agency: </span>
                                            <span>Viettel Consultancy and Services Joint Stock Company</span>
                                        </div>
                                        <div>
                                            <span class="title t_color_2">Business code: </span>
                                            <span>0100109106-075, the first registration dated March 23, 2010, issued by
                                                Hanoi Authority for Planning and Investment.
                                            </span>
                                        </div>

                                        <div>
                                            <span class="title t_color_2">Responsible for website content: </span>
                                            <span>Mr. Nguyen Dang Hung</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                    <div class="col-lg-6 brd_top brd_right rec_box" *ngFor="let item of departments; let dIndexx=index">
                        <div class="info_box inside_box department_box">
                            <a class="map_thumbnail" href="{{item.map}}" target="blank"><img
                                    [src]="'/assets/images/map/'+(dIndexx+1)+'.png'"></a>
                            <div class="department_content">
                                <h2>{{item.name}}</h2>
                                <div class="t_sarabun">
                                    <div>
                                        <span class="title t_color_2 t_bold">{{'COMMON.department' |translate}}: </span>
                                        <span>{{item.address}}</span>
                                    </div>
                                    <div>
                                        <span class="title t_color_2">{{'COMMON.phone' |translate}}: </span>
                                        <span> {{item.phoneNumber}}</span>
                                    </div>

                                    <div>
                                        <span class="title t_color_2">Email: </span>
                                        <span> {{item.email}}</span>
                                    </div>
                                    <div>
                                        <a class="t_color_3 t_italic" target="blank"
                                            href="{{item.map}}">[{{'APP.viettelheadquarteronmap' |translate}}]</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3 no-padding brd_top rec_box">
                <div class="info_box inside_box" id="subcribe_form">
                    <h2 class="t_bold">{{'COMMON.followus' |translate}}</h2>
                    <div>
                        <div>
                            <form (ngSubmit)="onSubmit()">
                                <div class="input-group">
                                    <input type="text" class="form-control" [formControl]="emailControl" [email]='true'
                                        [placeholder]="'COMMON.enteremail' | translate">
                                    <div class="input-group-btn">
                                        <button class="btn btn-default" type="submit">
                                            <img src="/assets/images/icons/paper_plane_white.png">
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div>{{'COMMON.subscribebyemail' |translate}}</div>
                    </div>


                </div>
            </div>
            <div id="govs">
                <a target="blank" href="https://dichvucong.mod.gov.vn/"><img src="/assets/images/gov3.png"></a>
                <a target="blank" href="http://www.mod.gov.vn/"><img src="/assets/images/gov2.png"></a>
                <a><img src="/assets/images/gov.png"></a>


            </div>

        </div>
    </div>

    <div class="row row-bottom">
        <div class="col-lg-6 brd_top brd_right" style="color:#e03">
            <!-- {{'APP.viettelcopyright' | translate}} -->
            Copyright © 2022 Viettel VTK
        </div>
        <div class="col-lg-3 brd_top brd_right">
            <div class="dropdown">
                <a class="link_website" data-toggle="dropdown">- - - {{'LINKWEBSITE.title' |translate}} - - -</a>
                <div class="dropdown-menu">
                    <a href="https://viettelconstruction.com.vn/">{{'LINKWEBSITE.viettelconstruction' |translate}}</a>
                    <a href="http://viettel.com.vn/vi">{{'LINKWEBSITE.viettelgroup' |translate}}</a>
                    <a href="http://viettelglobal.vn/">{{'LINKWEBSITE.viettelglobaljsc' |translate}}</a>
                </div>
            </div>
        </div>
        <div class="col-lg-3 brd_top" id="marmedia">
            <span>{{'LINKWEBSITE.developedby' |translate}}</span> <a href="http://marmedia.vn">MarMedia</a>
        </div>
    </div>
</footer>