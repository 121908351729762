import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '../basehttp.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'statistic');
  }
  getMonthStatistic() {
    //StatisticNewStatItem
    return this.sendGet(this.module);
  }
  getUserStats(type: MemberPointType) {
    //UserStatItemModel
    return this.sendGet(`${this.module}/user?Type=${type}`);
  }
  getUserChart() {
    //UserChartItemModel
    return this.sendGet(`${this.module}/chart?page=1&pageSize=10`);
  }
  getMonthlyNewStats() {
    //NewStatChartItemModel
    return this.sendGet(`${this.module}/new`);
  }
  getUserChartTotal(){
    //UserChartModel
    console.log('what?');
    return this.sendGet(`${this.module}/chart/total`);
  }
}
export enum MemberPointType {
  Author,
  Actor
}
export interface StatisticNewStatItem {
  type: StatisticNewStatEnum;
  current: number;
  total: number;
}
export enum StatisticNewStatEnum {
  News='news',
  Views ='views',
  Comments="comments"
}
export interface UserStatItemModel {
  type: UserActivityType;
  total: number;
  actualPoints: number;
  pointEach: number;
  maximumPoints: number;
}
export enum UserActivityType {
  WritePost = "writePost",
  Comment = "comment",
  Like = "like"
}
export interface UserChartItemModel {
  userId: number;
  fullName: string;
  authorPoints: number;
  actorPoints: number;
  totalPoints: number;
}
export interface NewStatChartItemModel {
  timeTypeId: number;
  totalViews: number;
  totalComments: number;
  totalNews: number;
  year: number;
}

export interface UserChartModel {
  year: number;
  authorPoints: number;
  actorPoints: number;
}