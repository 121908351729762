import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IBaseEntity } from 'src/app/core/components/base_component';
import { environment } from 'src/environments/environment';
import { BaseHttpService, PagnitionRequest } from '../basehttp.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService{
  constructor(_http: HttpClient) {
    super(_http,'admin/user');
  }
  GetAll(filter:GetAllUserQuery) {
    return this.sendPagingRequest(`${this.module}`,filter);
  }
  GetById(Id: number) {
    return this.sendGet(`${this.module}/${Id}`);
  }
  Create(model: CreateUserCommand) {
    return this.sendPost(`${this.module}`, model);
  }
  Update(Id: number, model: UpdateUserCommand) {
    return this.sendPut(`${this.module}/${Id}`, model);
  }
  DeActive(Id: number) {
    return this.sendPut(`${this.module}/changestatus/${Id}?Status=0`, null)
      .pipe(map((response: any) => response));
  }
  Active(Id: number) {
    return this.sendPut(`${this.module}/changestatus/${Id}?Status=1`, null)
      .pipe(map((response: any) => response));
  }
  Delete(Id: number) {
    return this.sendDelete(`${this.module}`,Id);
  }
}
export interface UserListItem extends IBaseEntity{
  userName: string;
  fullName: string;
  userType: number;
  status: number;
  createdOnDate: Date;
}
export class GetAllUserQuery extends PagnitionRequest{
  constructor() {
    super();

  }
  textSearch?: string;
  userType?: number;
  status?: number;
}
export interface UserDto {
  id: number;
  userType: number;
  userName: string;
  hashPassword: string;
  fullName: string;
  phoneNumber: string;
  position: string;
  departmentId: number;
  status: number;
  createdOnDate: Date | string;
}
export interface CreateUserCommand {
  userType: number;
  userName: string;
  hashPassword: string;
  fullName: string;
  phoneNumber: string;
  position: string;
  departmentId: number;
}
export interface UpdateUserCommand {
  id: number;
  password: string;
  fullName: string;
  phoneNumber: string;
  position: string;
  userType: number;
  departmentId: number;
}
export {UserTypes,UserStatuses}
var UserStatuses:any[]=[
  {
    value:1,
    label:'Đang hoạt động'
  },
  {
    value:0,
    label:'Đã bị khóa'
  }
]
var UserTypes:any[]=[
  {
    value:1,
    label:'Thành viên'
  },
  {
    value:2,
    label:'Người kiểm duyệt'
  },
  {
    value:3,
    label:'Biên tập viên'
  },
  {
    value:4,
    label:'Quản trị viên'
  }
]