<form [formGroup]="feedbackForm" (ngSubmit)="onSave()" class="t_sarabun">
    <div class="row">
        <div class="col-lg-4 form-group">
            <input type="text" [ngClass]="{ 'is-invalid': submited && f.fullName.errors }"
                [attr.disabled]="currentUser!=null?'':null" class="form-control input_box"
                [placeholder]="'*'+('COMMON.fullname' | translate)" formControlName="fullName">
            <div *ngIf="submited && f.fullName.errors" class="invalid-feedback">
                <div>{{'COMMON.requiredfield' | translate}}</div>
    
            </div>
        </div>
        <div class="col-lg-4 form-group">
            <input type="tel" class="form-control input_box" [attr.disabled]="currentUser!=null?'':null"
                [ngClass]="{ 'is-invalid': submited && f.phoneNumber.errors }"
                [placeholder]="'*'+('COMMON.phone' | translate)" formControlName="phoneNumber">
            <div *ngIf="submited && f.phoneNumber.errors" class="invalid-feedback">
                <div>{{'COMMON.requiredfield' | translate}}</div>
            </div>
        </div>
        <div class="col-lg-4 form-group">
            <input type="email" class="form-control input_box" [ngClass]="{ 'is-invalid': submited && f.email.errors }"
                placeholder="*Email" formControlName="email" [attr.disabled]="currentUser!=null?'':null">
            <div *ngIf="submited && f.email.errors" class="invalid-feedback">
                <div>{{'COMMON.requiredfield' | translate}}</div>
    
            </div>
        </div>
        <div class="col-lg-12 form-group">
            <input type="text" class="form-control input_box" [ngClass]="{ 'is-invalid': submited && f.title.errors }"
                [placeholder]="'*'+('COMMON.title' | translate)" formControlName="title">
            <div *ngIf="submited && f.title.errors" class="invalid-feedback">
                <div>{{'COMMON.requiredfield' | translate}}</div>
            </div>
        </div>
        <div class="col-lg-12 form-group">
            <textarea type="text" class="form-control input_box" formControlName="message"
                [ngClass]="{ 'is-invalid': submited && f.message.errors }"
                [placeholder]="'*'+('COMMON.message' | translate)"></textarea>
            <div *ngIf="submited && f.message.errors" class="invalid-feedback">
                <div>{{'COMMON.requiredfield' | translate}}</div>
    
            </div>
        </div>
    
        <div class="col-lg-12">
            <div class="bottom_area">
                <mat-checkbox class="example-margin" [color]="'primary'">
                    <div [innerHTML]="'GUIDES.agreement' | translate"></div>
                </mat-checkbox>
                <button type="submit" class="btn btn_color_2" id="btn_send"><img
                        src="/assets/images/icons/paper_plane_white.png"> {{'COMMON.send' | translate}}</button>
            </div>
    
        </div>
    </div>
   


</form>