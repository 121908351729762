<div id="top_middle">
    <div class="row brd_btm">

        <div class="col-lg-6 row">
            <div class="col-lg-6 brd_right">

            </div>
            <div class="col-lg-6 right_crossed brd_right">

            </div>
            <div class="inside_box padding_left page_title_area">
                <div class="page_breadcrum">
                    <a [routerLink]="['/']">{{'APP.home' | translate}}</a>
                    <a class="active">{{'APP.contact' | translate}}</a>
                </div>
                <h1 class="page_title">{{'APP.contact' | translate}}</h1>

            </div>
        </div>
        <div class="col-lg-6 row">

            <div class="col-lg-6 left_crossed">

            </div>
            <div class="col-lg-6 crossed brd_left">

            </div>
        </div>


    </div>
    <!-- <div class="scrolldown_sign t_color_3">{{'COMMON.scrolldown' | translate}}</div> -->
</div>
<div id="body_contact" >
    <div class="padding_left">
        <div class="row section_box">
            <div class="col-lg-6">
                <div *ngFor="let item of departments" class="info_box inside_box brd_btm">
                    <h3 class="t_bold t_color_2">{{item.name}}</h3>
                    <div class="t_sarabun">
                        <div>
                            <span class="title t_color_2 t_bold">{{'COMMON.department' | translate}}: </span>
                            <span>{{item.address}}</span>
                        </div>
                        <div>
                            <span class="title t_color_2">{{'COMMON.phone' | translate}}: </span>
                            <span>{{item.phoneNumber}}</span>
                        </div>
                        <div>
                            <span class="title t_color_2">Email: </span>
                            <span> {{item.email}}</span>
                        </div>
                        <div>
                            <a class="t_color_3 t_italic" target="blank" href="{{item.map}}">{{'APP.viettelheadquarteronmap' | translate}}</a>
                        </div>
                    </div>
    
                </div>
    
            </div>
            <div class="col-lg-6">
    
                <iframe id="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.8596311406986!2d105.79187634992827!3d21.038301792760915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab37899543c9%3A0x808b1dc5673ddb70!2sHancorp%20Plaza!5e0!3m2!1svi!2s!4v1607598510022!5m2!1svi!2s"
                    frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    
            </div>
        </div>
    </div>

    <div class="padding_left padding_right">
        <div class="letter_area">
            <h3 class="t_color_3 t_bold">{{'COMMON.writeletterforus' |translate}}</h3>
            <div class="form_letter">
                <div class="t_bold">
                    {{'GUIDES.contact' |translate}}
                </div>
                <br>
                <app-feedback-form></app-feedback-form>
    
            </div>
    
        </div>
    </div>
    
</div>