import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


export abstract class BaseHttpService {
  protected url: string;
  protected module: string = "";
  constructor(protected http: HttpClient, module: string) {
    this.module = module;
    this.url = environment.url + '/api';
  }

  protected sendGetDetail(url: string, id: number): Observable<Response<any>> {
    return this.http.get(`${this.url}/${url}/${id}`)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any | null>) => res)
      );
  }
  public sendPagingRequest<T extends PagnitionRequest>(url: string, params: T): Observable<Response<any[]>> {
    let url_queries: string = `${this.url}/${url}?`;
    for (let key in params) {
      if (params[key] != null)
        url_queries += `${key}=${params[key]}&`;
    }
    url_queries = url_queries.substr(0, url_queries.length - 1);
    return this.http.get(url_queries)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any[]>) => res)
      );
  }
  protected sendGet(url: string): Observable<Response<any>> {
    return this.http.get(`${this.url}/${url}`)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any>) => res)
      );
  }
  protected sendPost(url: string, model: any): Observable<Response<any>> {
    return this.http.post(`${this.url}/${url}`, model)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any>) => res)
      );
  }
  protected sendPostFormData(url: string, model: FormData): Observable<Response<any>> {
    return this.http.post(`${this.url}/${url}`, model, { headers: new HttpHeaders().append('Custom-Content-Type', 'true') })
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any>) => res)
      );
  }
  protected sendPutFormData(url: string, model: FormData): Observable<Response<any>> {
    return this.http.put(`${this.url}/${url}`, model, { headers: new HttpHeaders().append('Custom-Content-Type', 'true') })
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any>) => res)
      );
  }
  protected sendPut(url: string, model: any): Observable<Response<any>> {
    return this.http.put(`${this.url}/${url}`, model)
      .pipe(
        // catchError((error) => {
        //   console.log(error);
        //   return of({
        //     status: -1,
        //     message: error.toString()
        //   });
        // }),
        map((res: Response<any | null>) => res)
      );
  }
  public sendDelete(url: string, id: number) {
    return this.http.delete(`${this.url}/${url}/${id}`)
      .pipe(
        catchError((error) => {
          console.log(error);
          return of({
            status: -1,
            message: error.toString()
          });
        }),
        map((res: Response<any>) => res)
      );
  }

  //
  create(createModel: { [key: string]: any }) {
    return this.sendPost(`${this.module}`, createModel);
  }
  update(id: number, updateModel: { [key: string]: any }) {
    return this.sendPut(`${this.module}/${id}`, updateModel);
  }
  delete(id: number) {
    return this.sendDelete(this.module, id);
  }


}

export abstract class PagnitionRequest {
  constructor(page: number = 1, pageSize = 12) {
    this.page = page;
    this.pageSize = pageSize;
    this.isSortDesc = false;
  }
  page: number;
  pageSize: number;
  isSortDesc: boolean;
  sortField?: string;
}


export class Response<T> {
  data?: T
  status?: number;
  message?: string;
  totalCount?: number;
  dataCount?: number;
}

