import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { AppPreloadingStrategy } from './app_preloading_strategy';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { Roles } from './core/models/Roles';
import { WrapParnerSlideLayoutComponent } from './components/shared/wrap-parner-slide-layout/wrap-parner-slide-layout.component';
const routes: Routes = [
  {
    path: '',
    component: WrapParnerSlideLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        data: {
          preload: true
        }
      },
      {
        path: 'lien-he',
        pathMatch: 'full',
        component: ContactComponent,
        data: {
          preload: true
        }
      },
      {
        path: 'tin-tuc',
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule),
        data: {
          preload: true,
          title: 'Tin tức chung'
        }
      },
      {
        path: 'du-an',
        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule),
        data: {
          preload: true
        }
      },
      { path: 'others', loadChildren: () => import('./pages/others/others.module').then(m => m.OthersModule) },
      { path: 'ho-so', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
    ]
  },
  {
    path: 'tai-khoan',
    canActivate: [AuthGuard],

    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'quan-tri',
    canActivate: [AuthGuard],
    data: { roles: [Roles.Admin, Roles.Editor, Roles.Checker] },
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppPreloadingStrategy,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
