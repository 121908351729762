import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit,OnDestroy {
  lg_submitted: boolean;
  loginForm: FormGroup;
  public loading = false;
  private sub:SubSink=new SubSink();
  constructor(private formBuilder: FormBuilder,
    private _auth: AuthService,
    public router: Router,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      username: ['', Validators.required]
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  get lg_f() { return this.loginForm.controls; }
  ngOnInit(): void {

  }
  onLogin() {
    this.lg_submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    else {
      this.login();
    }

  }

  login() {
    this.loading = true;
    var credentials = {
      UserName: this.loginForm.controls["username"].value,
      PassWord: this.loginForm.controls["password"].value,
    };
    this.sub.sink=this._auth.login(credentials)
      .subscribe((res: any) => {
        if (res.status == 1) {
          
          this.dialogRef.close();
          alert("Đăng nhập thành công");
          //this.router.navigate(["/tai-khoan"]);
        }
        else if (res.status == 0) {
          alert(res.message);
          // this._snackBar.open(res.message);
        }
        else if (res.status == -1) {
          alert("Đăng nhập không thành công");
          this._snackBar.open("Đăng nhập không thành công");
        }
      }, error => {
        alert("Đăng nhập không thành công");
        // this._snackBar.open("Đăng nhập không thành công");
        console.log(error);
      }).add(() => this.loading = false);
  }
}