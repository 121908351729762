import { Component, OnInit, isDevMode } from '@angular/core'

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent implements OnInit {
  isDev = isDevMode()
  siteLanguage: string
  siteLocale: string
  languageList = [
    { code: 'en', label: 'English' },
    { code: 'vi', label: 'Vietnamese' }
  ]

  ngOnInit() {
    this.siteLocale = window.location.pathname.split('/')[1]
    this.siteLanguage = this.languageList.find(
      (f) => f.code === this.siteLocale
    )?.label
    if (!this.siteLanguage) {
      this.onChange(this.languageList[0].code)
    }
  }

  onChange(selectedLangCode: string) {
    window.location.href = `/${selectedLangCode}`
  }

}
