import { Injectable } from '@angular/core';
import { AES, SHA256, enc, mode, pad } from 'crypto-js';
@Injectable({ providedIn: 'root' })
export class EncryptService {

  constructor() { }
  secretKey = enc.Utf8.parse("E6t187^D43%FiqGnhOyV1IfYrxrQyLil"); 
  iv = enc.Utf8.parse('7061737323313233');
  encrypt(value: string): string {
    return AES.encrypt(enc.Utf8.parse(value), SHA256(this.secretKey),
      {
        keySize: 256 / 8,
        iv: this.iv,
        mode: mode.CBC,
        padding: pad.Pkcs7
      }).toString();
   // return AES.encrypt(value, this.secretKey.trim()).toString();
  }
  decrypt(textToDecrypt: string) {
    return AES.decrypt(textToDecrypt, SHA256(this.secretKey), {
      keySize: 256 / 8,
      iv: this.iv,
      mode: mode.CBC,
      padding: pad.Pkcs7
    }).toString(enc.Utf8);
  }
}
