import { HttpClient } from '@angular/common/http';
import {  Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OperationfieldService {

  url = environment.url;
  constructor(private _http: HttpClient) { }
  GetAll() {
    return this._http.get(`${this.url}/api/operationfield`)
      .pipe(map((response: any) => response))
  }
  GetById(Id:number) {
    return this._http.get(`${this.url}/api/operationfield/${Id}`)
      .pipe(map((response: any) => response));
  }
}
export interface OperationFieldListItem {
  id: number;
  name: string;
  thumbnail: string;
  banner:string;
  description:string;
}
export interface OperationFieldItem
{
    id: number;
    name: string;
    thumbnail: string;
    htmlContent: string;
}