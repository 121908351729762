import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, SessionUserModel } from 'src/app/services/auth/auth.service';
import { ContactService, FeedBackModel } from 'src/app/services/contact/contact.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit, OnDestroy {
  feedbackForm: FormGroup;
  submited: boolean;
  currentUser: SessionUserModel;
  private sub: SubSink = new SubSink();
  constructor(private formBuilder: FormBuilder,
    private _service: ContactService,
    private _auth: AuthService
  ) {
    this.feedbackForm = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required]],
      title: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });

  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  get f() { return this.feedbackForm.controls; }
  ngOnInit(): void {
    this.sub.sink = this._auth.currentUser.subscribe(res => {
      this.currentUser = res;
      this.initForm();
    });
  }
  initForm() {
    if (this.currentUser) {
      this.feedbackForm.setValue({
        'fullName': this.currentUser.fullName,
        'phoneNumber': this.currentUser.phoneNumber,
        'email': this.currentUser.userName,
        'title': '',
        'message': ''
      })
    }
    else this.feedbackForm.reset();

  }
  onSave() {

    if (this.currentUser) {
      console.log(this.feedbackForm.controls['title'].value);
      console.log(this.feedbackForm.controls['message'].value);
      if ((this.feedbackForm.controls['title'].value.length == 0) || (this.feedbackForm.controls['message'].value.length == 0))
      {
        alert("Mời nhập tiêu đề và nội dung");
        return;
      }
    }
    else {
      this.submited = true;
      if (this.feedbackForm.invalid) return;
    }

    let model: FeedBackModel = {
      fullName: this.f["fullName"].value,
      phoneNumber: this.f["phoneNumber"].value,
      email: this.f["email"].value,
      title: this.f["title"].value,
      message: this.f["message"].value
    }
    this.sub.sink = this._service.SendFeedBack(model).subscribe(res => {
      alert('Gửi phản hồi/góp ý thành công');
      this.submited = false;
      this.initForm();
    },
      err => {
        alert('Gửi phản hồi không thành công. Hãy kiểm tra các trường thông tin đã nhập đầy đủ chưa?');
      }
    )
  }
}
