import { NgModule } from '@angular/core';
import { UserBoxComponent } from './components/shared/user-box/user-box.component';
import { UserCommentBoxComponent } from './components/shared/user-comment-box/user-comment-box.component';
import { ShareSocialBoxComponent } from './components/shared/share-social-box/share-social-box.component';
import { BreadcrumbComponent } from './components/shared/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//
import { ListProjectsGridComponent } from './components/shared/list-projects-grid/list-projects-grid.component';
import { MaterialModule } from './material.module';
import { FeedbackFormComponent } from './components/shared/feedback-form/feedback-form.component';
import { FullscreenPopupComponent } from './components/shared/dialogs/fullscreen-popup/fullscreen-popup.component';
import { PartnersSlideshowComponent } from './components/shared/partners-slideshow/partners-slideshow.component';
import { ConfirmDialogComponent } from './components/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from './components/shared/dialogs/info-dialog/info-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CustomSnackbarComponent } from './components/shared/custom-snackbar/custom-snackbar.component';
import { NgxLoadingModule } from 'ngx-loading';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
///
import { SafeHtmlPipe, SafeUrlPipe, CheckScriptsPipe, SafeResourceUrlPipe, OembedPipe } from './core/pipes/safeHtml.pipe';
import { ArticleFeedbackListComponent } from './components/shared/article-feedback-list/article-feedback-list.component';
import { dateToTimePipe, GroupByPipe, secondsToMinutesPipe, secondsToTimePipe, timeAgoPipe } from './core/pipes/time.pipe';
import { NgxMasonryModule } from 'ngx-masonry';
import { ChartsModule } from 'ng2-charts';
import { memberPointTypePipe, newStatusPipe, StatisticNewStatPipe, userActivityTypePipe, userStatusPipe, userTypePipe } from './core/pipes/news.pipe';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    SafeHtmlPipe, SafeUrlPipe, CheckScriptsPipe, SafeResourceUrlPipe, OembedPipe,
    userTypePipe,userStatusPipe,newStatusPipe,memberPointTypePipe,userActivityTypePipe,
    StatisticNewStatPipe,
    BreadcrumbComponent,
    UserBoxComponent,
    UserCommentBoxComponent,
    ShareSocialBoxComponent,
    ListProjectsGridComponent,
    FeedbackFormComponent,
    FullscreenPopupComponent,
    PartnersSlideshowComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    CustomSnackbarComponent,
    ArticleFeedbackListComponent,
    timeAgoPipe,
    secondsToTimePipe,
    dateToTimePipe, GroupByPipe,
    secondsToMinutesPipe,
    newStatusPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule, FormsModule,
    MaterialModule,
    LazyLoadImageModule,
    InfiniteScrollModule,
    NgxMasonryModule,
    SlickCarouselModule,
    TranslateModule
  //   TranslateModule.forChild({
  //     loader: {provide: TranslateLoader, useClass: CustomLoader},
  //     compiler: {provide: TranslateCompiler, useClass: CustomCompiler},
  //     parser: {provide: TranslateParser, useClass: CustomParser},
  //     missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomHandler},
  //     isolate: true
  // })
  ],
  exports: [
    SafeHtmlPipe, SafeUrlPipe, CheckScriptsPipe, SafeResourceUrlPipe, OembedPipe,newStatusPipe,
    userTypePipe,userStatusPipe,memberPointTypePipe,userActivityTypePipe,
    StatisticNewStatPipe,
    SlickCarouselModule,
    UserBoxComponent,
    UserCommentBoxComponent,
    ShareSocialBoxComponent,
    BreadcrumbComponent,
    ListProjectsGridComponent,
    MaterialModule,
    FeedbackFormComponent,
    FullscreenPopupComponent,
    PartnersSlideshowComponent,
    LazyLoadImageModule,
    NgxLoadingModule,
    ArticleFeedbackListComponent,
    timeAgoPipe,
    secondsToTimePipe,
    dateToTimePipe, GroupByPipe,
    secondsToMinutesPipe,
    InfiniteScrollModule,
    ReactiveFormsModule, FormsModule,
    ChartsModule,
    TranslateModule
  ]
})
export class SharedModule { }
