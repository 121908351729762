import { Component, OnDestroy, OnInit } from '@angular/core';
import { getImageUploadUrl, UploadUrls } from 'src/app/app.config';
import { HomeService, PartnerItemModel } from 'src/app/services/home/home.service';
import { SubSink } from 'subsink';
declare var $: any;
@Component({
  selector: 'app-partners-slideshow',
  templateUrl: './partners-slideshow.component.html',
  styleUrls: ['./partners-slideshow.component.scss']
})
export class PartnersSlideshowComponent implements OnInit,OnDestroy {
  private sub:SubSink=new SubSink();
  loaded: boolean;
  partners: PartnerItemModel[];
  root_image = getImageUploadUrl(UploadUrls.PARTNER) + "/";
  constructor(private _service: HomeService) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    this.loaded = false;
    this.sub.sink=this._service.GetPartners().subscribe(res => {
      if (res) {
        this.partners = res;
      }
      else this.partners = [];
    }).add(() => this.loaded = true);
  }
}
