import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { getImageUploadUrl, UploadUrls } from 'src/app/app.config';
import { HomeService, HomeSettingsResponse } from 'src/app/services/home/home.service';
import { OperationFieldListItem, OperationfieldService } from 'src/app/services/operationfield/operationfield.service';
import { ProjectListItem } from 'src/app/services/project/project.service';
import { SubSink } from 'subsink';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  data: HomeSettingsResponse;
  projects: ProjectListItem[]
  fields: OperationFieldListItem[];
  image_data_root: string = getImageUploadUrl(UploadUrls.PROJECT);
  private sub: SubSink = new SubSink();
  constructor(private _service: HomeService, private _field: OperationfieldService) {

  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  loadBannerSlider() {
    $('#banner .banner_slide').on('init', function (event, slick) {

      $('#banner .hover_panel.left img').on('click', function () {
        slick.slickPrev();
       
      });
      $('#banner .hover_panel.right img').on('click', function () {
        slick.slickNext();
      });
    })
    $('#banner .banner_slide').slick({
      // fade:true,
      autoplay: true,
      autoplaySpeed: 4000,
      // lazyLoad: "progressive",
      speed: 600,
      arrows: false,
      dots: false,
      fade: true,
      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
      prevArrow: "#banner .hover_panel.left img",
      nextArrow: "#banner .hover_panel.right img"
    });
  }
  loadFieldSliders() {

    let total_slides = '0' + $('.banner_slicker .banner_item').length;
    $('#slide_nav .paging span.total_').text(total_slides);
    $('#banner_project .banner_slicker').slick({
      // fade:true,
      autoplaySpeed: 4000,
      autoplay: true,
      // lazyLoad: "progressive",
      speed: 600,
      asNavFor: '#banner_project .slide_info_box',
      arrows: false,
      dots: false,

      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
    });

    $('#banner_project .slide_info_box').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $('#slide_nav .paging span.current_').text('0' + (nextSlide + 1));
    })
    $('.slide_info_box').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '#banner_project .banner_slicker',
      fade: true,
      dots: false,
      appendArrows: $('#slick_arrow')
    });


  }

  ngOnInit(): void {
    this.sub.sink = this._service.GetAll().subscribe(res => {
      if (res) {
        this.data = res;
        let projects: ProjectListItem[] = [];
        this.data.projects.forEach(element => {
          projects.push({
            id: element.id,
            title: element.title,
            thumbnail: element.thumbnail,
            category: element.category,
            thumbnailRatio: element.thumbnailRatio
          });
        });
        this.projects = projects;
        console.log(this.data.banners);
        setTimeout(() => {
          this.loadBannerSlider();
        }, 300);
      }
    })
    this.sub.sink = this._field.GetAll().subscribe(res => {
      this.fields = res;
      setTimeout(() => {
        this.loadFieldSliders();
      }, 300);
    })
  }

}
