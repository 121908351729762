<div id="banner">
    <div class="banner_slide" *ngIf="data">
        <div class="item" *ngFor="let item of data.banners;let i=index"
            [lazyLoad]="image_data_root+'/'+item.id+'/'+item.banner"
            [defaultImage]="'/assets/images/project/banner.png'">
            <div class="grids">
                <div class="grid_box brd_btm "></div>
                <div class="grid_box brd_btm right_crossed brd_right"></div>
                <div class="grid_box brd_btm left_crossed brd_right"></div>
                <div class="grid_box brd_btm left_crossed"></div>
                <div class="grid_box brd_right right_crossed"></div>
                <div class="grid_box brd_right left_crossed"></div>
                <div class="grid_box left_crossed brd_right"></div>
                <div class="grid_box right_crossed"></div>
            </div>
            <div class="banner_info padding_left padding_right">
                <div class="info">
                    <!-- <div class="slide_index" *ngIf="data.banners?.length>1">
                        <span>0{{i+1}}</span>
                        <span class="line"></span>
                        <span>0{{i+2}}</span>
                    </div> -->
                    <h2 [innerHTML]="item.title">
                    </h2>
                    <div class="text" [innerHTML]="item.summary">

                    </div>
                </div>
                <div class="view_detail">
                    <a [routerLink]="[ '/du-an/chi-tiet', item.id ]">{{'PROJECT.viewdetail' |translate}}</a>
                </div>

            </div>
        </div>
    </div>
    <div class="hover_panel left" style="background-image: url(/assets/images/home/bg_hover.png);">
        <img src="/assets/images/home/arrow.png">
    </div>
    <div class="hover_panel right" style="background-image: url(/assets/images/home/bg_hover.png);">
        <img src="/assets/images/home/arrow.png">
    </div>
</div>
<div class="body">
    <div class="body_section padding_left padding_right rec_section" id="about_us">
        <div class="about_article padding_left padding_right">
            <div *ngIf="data" class="ck-content" [innerHTML]="data.homeAboutArticle | safeHtml">

            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 rec_box crossed">

            </div>
            <div class="col-lg-6 rec_box brd_left left_crossed">

            </div>

        </div>
    </div>
    <div class="body_section">
        <div id="bodyPage-product">
            <app-list-projects-grid [projects]="projects"></app-list-projects-grid>

        </div>
    </div>
    <div class="body_section rec_section_4 rec_section">
        <div class="row">
            <div class="col-lg-3 rec_box">
                <div class="title_section padding_left">
                    <h2 class="t_bold t_color_2">{{'APP.business' |translate}}
                    </h2>
                </div>

            </div>
            <div class="col-lg-3 rec_box right_crossed"></div>
            <div class="col-lg-3 rec_box crossed brd_left brd_right">



            </div>
            <a class="col-lg-3 rec_box view_more_box" [routerLink]="['/du-an']">
                <div class="inside_box">
                    <div class="text-center">
                        <div class="t_bold">{{'PROJECT.viewall' |translate}}</div>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="body_section">
        <app-home-operation-fields [items]="fields"></app-home-operation-fields>
    </div>
</div>