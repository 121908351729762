import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IBaseEntity } from 'src/app/core/components/base_component';
import { environment } from 'src/environments/environment';
import { BaseHttpService, PagnitionRequest } from '../basehttp.service';
import { ItemList } from '../category/category.service';
import * as _moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class NewsService extends BaseHttpService {
  constructor(_http: HttpClient) {
    super(_http, "news");
  }
  getUserNews(filter: GetAllUserNewsQuery) {
    return this.sendPagingRequest(`${this.module}/user`, filter);
  }

  getAllPrivateNews(filter: GetAllNewDisplayFilter) {
    if (filter.dateEnd) {
      filter.dateEnd = _moment(filter.dateEnd).format('YYYY-MM-DD');
    }
    if (filter.dateFrom) {
      filter.dateFrom = _moment(filter.dateFrom).format('YYYY-MM-DD');
    }
    //return this.sendPagingRequest(`${this.module}`,filter);
    return this.sendPagingRequest(`${this.module}/display`, filter);
  }
  getAllNews(filter: GetAllNewsFilter) {
    return this.sendPagingRequest(`${this.module}`, filter);
  }

  getRelatedNews(Id: number) {
    return this.sendGet(`${this.module}/display/${Id}/relates`);
  }
  GetById(Id: number) {
    return this.sendGet(`${this.module}/${Id}`);
  }
  GetDetail(Id: number, update: boolean = true) {
    return this.sendGet(`${this.module}/detail/${Id}?update=${update}`);
  }
  Create(CreateModel: CreateNewCommand, thumbnail: File) {
    const formData: FormData = new FormData();
    formData.append('thumbnail', thumbnail, thumbnail.name);
    formData.append('data', JSON.stringify(CreateModel));
    return this.sendPostFormData(this.module, formData)
      .pipe(map((response: any) => response));
  }
  Update(Id: number, UpdateModel: UpdateNewDetailCommand, thumbnail: File) {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(UpdateModel));
    if (thumbnail)
      formData.append('thumbnail', thumbnail, thumbnail.name);
    return this.sendPutFormData(`${this.module}/${Id}`, formData);
  }
  ChangeStatus(Id: number, Status: number) {
    return this.sendPost(`${this.module}/changestatus/${Id}?Status=${Status}`, null)
      .pipe(map((response: any) => response));
  }
  Delete(Id: number) {
    return this.sendDelete(`${this.module}`, Id);
  }
  Like(Id: number,Value:boolean=true) {
    return this.sendPost(`${this.module}/${Id}/like?Value=${Value}`, null);
  }
  IsLike(Id: number) {
    return this.sendGet(`${this.module}/${Id}/like`);
  }
  CreateFeedBack(model: NewFeedbackCreateModel) {
    return this.sendPost(`newfeedback`, model);
  }
  DeleteFeedback(Id: number) {
    return this.sendDelete(`newfeedback`, Id);
  }
  getAllFeedback(NewId: number) {
    return this.sendGet(`newfeedback?id=${NewId}&page=1&&pageSize=100`)
      .pipe(map((response: any) => response));
  }
}

export interface NewFeedbackCreateModel {
  newId: number;
  htmlContent: string;
}

export interface NewFeedbackModel {
  id: number;
  htmlContent: string;
  createdByUserId: number;
  createdOnDate: string;
  lastModifiedOnDate: string;
}

export interface UserNewListItem extends IBaseEntity {
  title: string;
  titleEn: string;
  publishedOnDate: Date;
  categoryId: number;
  category: string;
  categoryEn: string;
  status: number;
}
export class GetAllUserNewsQuery extends PagnitionRequest {
  constructor(userId: number) {
    super();
    this.userId = userId;
  }
  userId: number;
  categoryId?: number;
  status?: number;
  title?: string;
}
var NewStatus: ItemList[] = [
  {
    value: 0,
    label: "Đợi phê duyệt"
  },
  {
    value: 1,
    label: "Đã phê duyệt"
  },
  {
    value: 2,
    label: "Góp ý điều chỉnh"
  }
];
export { NewStatus };

///////
export interface CreateNewCommand {
  title: string;
  htmlContent: string;
  categoryId: number;
  thumbnail?: any;
}
export interface UpdateNewDetailCommand {

  id: number;
  title: string;
  htmlContent: string;
  categoryId: number;
  thumbnail?: any;
}
///
export interface NewListItem {
  id: number;
  title: string;
  categoryId: number;
  category: string;
  authorId: number;
  author: string;
  status: number;
  summary: string;
  publishedOnDate: Date | string;
}
export class GetAllNewsFilter extends PagnitionRequest {
  constructor() {
    super();

  }
  categoryId?: number;
  title?: string;
  status?: number;
}
export interface NewItem {
  id: number;
  title: string;
  thumbnail: string;
  summary: string;
  htmlContent: string;
  categoryId: number;
  categoryType: number;
  status: number;
  createdByUserId: number;
  createdOnDate: Date | string;
  lastModifiedOnDate: Date | string;
  lastModifiedByUserId: number;
  author: string;
}
//

export interface NewDetailItem {
  id: number;
  title: string;
  summary: string;
  thumbnail: string;
  htmlContent: string;
  categoryId: number;
  category: string;
  totalViews: number;
  totalComments: number;
  publishedOnDate: Date | string;
  status: number;
  authorId: number;
  author: string;
}


///
export class GetAllNewDisplayFilter extends PagnitionRequest {
  constructor(cateType: number, page: number = 1, pageSize: number = 12) {
    super(page, pageSize);
    this.cateType = cateType;
  }
  cateType: number;
  categoryId?: number;
  dateFrom?: any;
  dateEnd?: any;
  title?: string;
}
export interface NewDisplayListItem {
  id: number;
  title: string;
  categoryId: number;
  category: string;
  authorId: number;
  author: string;
  thumbnail: string;
  totalComments: number;
  totalViews: number;
  publishedOnDate: Date | string;
}



export interface RelatedNewListItem {
  id: number;
  title: string;
  thumbnail: string;
  totalComments: number;
  totalViews: number;
  publishedOnDate: string;
}