import { Component, Input, OnInit } from '@angular/core';
import { getImageUploadUrl, UploadUrls } from 'src/app/app.config';
import { OperationFieldListItem } from 'src/app/services/operationfield/operationfield.service';

@Component({
  selector: 'app-home-operation-fields',
  templateUrl: './home-operation-fields.component.html',
  styleUrls: ['./home-operation-fields.component.scss']
})
export class HomeOperationFieldsComponent implements OnInit {
  @Input('items') fields: OperationFieldListItem[];
  image_data_root: string = getImageUploadUrl(UploadUrls.OPERATION_FIELD);
  constructor() { }

  ngOnInit(): void {
  }

}
