import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  url = environment.url;
  constructor(private _http: HttpClient, @Inject(LOCALE_ID) protected localeId: string) {

  }
  GetAllComment(query:GetAllCommentQuery) {
    return this._http.get(`${this.url}/api/comment?targetId=${query.targetId}&targetType=${query.targetType}&page=${query.page}&pageSize=${query.pageSize}`)
      .pipe(map((response: any) => {
        if(response){
          return response;
        }
        else return null;
      }));
  }
  AddComment(model:CreateCommentCommand){
    return this._http.post(`${this.url}/api/comment`,model)
    .pipe(map((response: any) => {
      if(response){
        return response;
      }
      else return null;
    }));
  }
  DeleteComment(id:number){
    return this._http.delete(`${this.url}/api/comment/${id}`)
    .pipe(map((response: any) => {
      if(response){
        return response;
      }
      else return null;
    }));
  }
}

export interface GetAllCommentQuery {
  targetId: number;
  targetType: CommentTargetTypeEnum;
  page: number;
  pageSize: number;
}

export interface CommentListItem {
  id: number;
  fullName: string;
  email: string;
  commentContent: string;
  userId: number | null;
  addedOnDate: string;
  lastUpdatedOnDate: string;
}
export enum CommentTargetTypeEnum {
  New = 1,
  PublicNew = 2
}
export interface CreateCommentCommand {
  fullName: string;
  email: string;
  commentContent: string;
  targetType: CommentTargetTypeEnum;
  targetId: number;
}


export interface Comment {
  id: number;
  fullName: string;
  email: string;
  commentContent: string;
  targetType: CommentTargetTypeEnum;
  targetId: number;
  userId: number;
  addedOnDate: string;
  lastUpdatedOnDate: string;
}