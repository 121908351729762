import { HttpClient } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  url = environment.url;
  constructor(private _http: HttpClient, @Inject(LOCALE_ID) protected localeId: string) {

  }
  getAll(page:number=1,pageSize:number=10) {
    return this._http.get(`${this.url}/api/notification?page=${page}&pageSize=${pageSize}`)
      .pipe(map((response: any) => response));
  }
  seen(id:number) {
    return this._http.put(`${this.url}/api/notification/${id}`,null)
      .pipe(map((response: any) => response));
  }
  getTotalUnseen() {
    return this._http.get(`${this.url}/api/notification/totalunseen`)
      .pipe(map((response: any) => response));
  }
}

export interface NotificationResponse {
  id: number;
  entityType: NotificationEntityType;
  entityId: number;
  actor: string;
  actorId: number;
  actionType: string;
  entityName: string;
  dateCreated: Date;
  status: boolean;
  isPrivateNew:boolean;
}

export enum NotificationEntityType {
  CreateNew = "createNew",
  EditNew = "editNew",
  LikeNew = "likeNew",
  CommentNew = "commentNew",
  ConfirmNew = "confirmNew",
  AlertNew = "alertNew",
  DeleteNew = "deleteNew"
}