import { Component, OnDestroy, OnInit,ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContactService, DepartmentModel } from 'src/app/services/contact/contact.service';
import { SubscribedEmailService } from 'src/app/services/subscribed-email/subscribed-email.service';
import { SubSink } from 'subsink';
import { isBuffer } from 'util';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class FooterComponent implements OnInit,OnDestroy {
  private sub:SubSink=new SubSink();
  departments:DepartmentModel[];
  emailControl=new FormControl();
  currentLang:string="vi";
  constructor(private _service:ContactService,private _subscribe:SubscribedEmailService,
    public translate: TranslateService,
    ) { }
  ngOnDestroy(): void {
   this.sub.unsubscribe();
  }


  ngOnInit(): void {
    this.sub.sink=this._service.GetDepartments().subscribe(res=>{
      this.departments=res;
    });
    
    this.sub.sink=this.translate.onLangChange.subscribe(val=>{
      this.currentLang=this.translate.currentLang;
    });
  }
  onSubmit(){
    if(!this.emailControl.valid){
      alert('Email không hợp lệ');
      return;
    }
    this.sub.sink=this._subscribe.SubscribeEmail(this.emailControl.value)
    .subscribe(res=>{
      if(res.status==1){

        alert('Đăng ký theo dõi thành công');
        this.emailControl.reset();
      }
      else alert(res.message);
    },
    error=> alert('Đăng ký theo dõi không thành công')
    );
  }
}
