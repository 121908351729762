import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userSubject: BehaviorSubject<UserBoxInfo> = new BehaviorSubject(null);
  currentUserStat: Observable<UserBoxInfo>;
  url = environment.url;
  constructor(private _http: HttpClient) {
    this.currentUserStat = this.userSubject.asObservable();
  }
  GetMyInfo() {
    return this._http.get(`${this.url}/api/account`)
      .pipe(map((response: CurrentUserDetail) => response));
  }
  UpdateInfo(phoneNumber:string) {
    return this._http.put(`${this.url}/api/account`, {phoneNumber:phoneNumber})
      .pipe(map((response: any) => response));
  }
  UpdateAvatar(file: File) {
    const formData = new FormData();
    formData.append('avatar', file, file.name);
    return this._http.post(`${this.url}/api/account/updateavatar`, formData, { headers: new HttpHeaders().append('Custom-Content-Type', 'true') })
      .pipe(map((response: any) => response));
  }
  UpdatePassword(password:String,newPassword:String) {
   
    return this._http.post(`${this.url}/api/authenticate/updatepassword`,{
      CurrentPassword:password,
      NewPassword:newPassword,
      ConfirmNewPassword:newPassword
    })
      .pipe(map((response: any) => response));
  }
  GetMyStats(id:number) {
    if (this.userSubject.value) return this.userSubject;
    return this._http.get(`${this.url}/api/account/infobox/${id}`)
      .pipe(
        map((res: any) => {
          if (res && res.status == 1) {
            this.userSubject.next(res.data);
            return res.data;
          }
          else return null;
        })
      );
  }
}
export interface CurrentUserDetail {
  id: number;
  userType: number;
  userName: string;
  password: string;
  fullName: string;
  phoneNumber: string;
  position: string;
  departmentId: number;
  department:string;
  createdOnDate:Date
}

export interface UserChangeInfoCommand {
  phoneNumber: string;
  fullName: string;
  position: string;
  departmentId: number;
}
export interface UserBoxInfo {
  id: number;
  fullName: string;
  position: string;
  addedOnDate: Date | string;
  totalNewsWritten: number;
  totalInteractions: number;
  totalViews: number;
}