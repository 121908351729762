<div class="padding">
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <div class="form-group input_group"
            [ngClass]="{ 'is-invalid': lg_submitted && lg_f.username.errors }">
            <div class="icon">
                <img src="/assets/images/icons/avatar.png">
            </div>

            <input type="text" class="form-control" placeholder="Tài khoản" cdkFocusInitial
                formControlName="username">
            <div *ngIf="lg_submitted && lg_f.username.errors" class="invalid-feedback">
                <div *ngIf="lg_f.username.errors.required">Mời nhập tài khoản</div>
            </div>
        </div>
        <div class="form-group input_group"
            [ngClass]="{ 'is-invalid': lg_submitted && lg_f.password.errors }">
            <div class="icon">
                <img src="/assets/images/icons/key.png">
            </div>

            <input type="password" class="form-control" placeholder="Mật khẩu" formControlName="password">
            <div *ngIf="lg_submitted && lg_f.password.errors" class="invalid-feedback">
                <div *ngIf="lg_f.password.errors.required">Mời nhập mật khẩu</div>
            </div>
        </div>
        <div class="form-group flex_box">
            <mat-checkbox><span class="t_color_2">Ghi nhớ mật khẩu</span></mat-checkbox>
            <!-- <a href="#" class="t_color_3">Tìm lại mật khẩu</a> -->
        </div>


        <button type="submit" class="submit_btn btn_color_3" id="login_btn">ĐĂNG NHẬP</button>
    </form>
    <ngx-loading [show]="loading"></ngx-loading>
</div>