import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
//import { InformationDialogComponent } from '../components/shared/information-dialog/information-dialog.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, public dialog: MatDialog) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401].includes(err.status) && this.authenticationService.GetUser()) {
                // auto logout if 401 or 403 response returned from api
                return this.handle401Error(request, next);
                // this.authenticationService.logout();
            }
            else if (err.status == 500) {
                //alert("something went wrong!");
                
                // if (!this.dialog.getDialogById('error_network')) {
                //     this.dialog.open(InformationDialogComponent, {
                //         id: 'error_network',
                //         data: {
                //             message: err.error + ". " + err.message,
                //             title: 'Đã xảy ra lỗi'
                //         }
                //     });
                // }
            }
            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(err);
            return throwError(error);
        }))
    }

    private isRefreshing = false;

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            return this.authenticationService.refreshToken().pipe(
                switchMap((token: any) => {
                    if (token) this.isRefreshing = false;
                    this.authenticationService.storeTokens(token);
                    return next.handle(this.addToken(request, this.authenticationService.getJwtToken()));
                }));

        } else {
            this.authenticationService.logout();
        }
    }
    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
}
