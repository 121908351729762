
import { BaseHttpService, Response, PagnitionRequest } from "src/app/services/basehttp.service";
import { AfterViewChecked, ChangeDetectorRef, Component, Directive, Injectable, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from "subsink";
import { debounceTime, finalize, map, switchMap, tap } from "rxjs/operators";
import { LazyLoadEvent } from "primeng/api";
import { AuthService, SessionUserModel } from "src/app/services/auth/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";

// @Component({
//     template: ''
// })
@Directive()
export abstract class BasePagingTableComponent<T extends IBaseEntity, S extends PagnitionRequest> implements OnDestroy, AfterViewChecked {
    protected subs: SubSink = new SubSink();
    public tableItems: T[] = [];
    public selectedTableITem?: T;
    public tb_loading: boolean = false;
    public total_records: number = 0;
    protected _baseService: BaseHttpService;
    currenntUser?: SessionUserModel;
    protected _auth: AuthService;
    protected _snackBar: MatSnackBar;
    textSearchControl: FormControl = new FormControl();
    protected constTableArgs: S;
    constructor(public tableArgs: S, private _cdr: ChangeDetectorRef, service: BaseHttpService) {
        const injector = AppInjector.getInjector();
        this._baseService = service;
        this._auth = injector.get(AuthService);
        this._snackBar = injector.get(MatSnackBar);
        this.tableArgs = tableArgs;
        this.constTableArgs = { ...this.tableArgs };
        this.subs.sink = this._auth.currentUser.subscribe(res => this.currenntUser = res);
    }
    ngAfterViewChecked(): void {
        this._cdr.detectChanges();
    }

    abstract onTableLazyLoad(event: LazyLoadEvent): void;
    abstract onDeleteRow(id: number): void;

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    protected onPagingTableDataRecieved(response: Response<T[]>): void {
        //  this.tb_loading = false;
        if (!this.tableItems) this.tableItems = [];
        if (response.status == 1) {
            this.tableItems = response.data ?? [];
            this.total_records = response.dataCount ?? 0;

        }
        else if (response.status == 0) {
            alert(response.message ?? "Đã xảy ra lỗi khi tải dữ liệu");
        }
        else alert("Đã xảy ra lỗi khi tải dữ liệu");

    }
    protected loadTableData(url: string, event?: LazyLoadEvent) {
        console.log(event);
        this.tableArgs = { ...this.constTableArgs };
        this.tableArgs.page = (event.first! / event.rows!) + 1;
        this.tableArgs.pageSize = event.rows!;
        this.tableArgs.sortField = event.sortField;
        this.tableArgs.isSortDesc = event.sortOrder != 1;
        if (event) {
            if (event.filters) {
                for (let key in event.filters) {
                    if (event.filters![key]) {
                        (this.tableArgs as any)[key] = event.filters![key].value;
                    }
                }
            }
        }
        console.log(this.tableArgs);
        this.tb_loading = true;
        this.subs.sink = this._baseService.sendPagingRequest(url, this.tableArgs)
            .pipe(finalize(() => this.tb_loading = false))
            .subscribe(res => this.onPagingTableDataRecieved(res));
    }
    protected showDeleteConfirm(
        url: string, id: number, message?: string
    ) {
        let entityName: string = undefined;
        if (confirm(message ?? `Bạn chắc chắn muôn xóa ${entityName ?? 'dòng'} này chứ?`)) {
            this._baseService.delete(id).subscribe(res => {
                if (res.status == 1) {

                    this._snackBar.open(`Đã xóa thành công ${entityName ?? ''}`);
                    this.tableItems = this.tableItems.filter(x => x.id != id);
                }
                else if (res.status == 2) {
                    alert(res.message ?? "");
                }
                else alert("Xóa không thành công");
            })
        }
    }
    protected updateRowData(id: number, data: T) {
        let tableItems = [...this.tableItems];
        tableItems[this.findIndexById(id)] = data;
        this.tableItems = tableItems;
    }
    private findIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.tableItems.length; i++) {
            if (this.tableItems[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }
    protected textSearchControlListen(url: string, initalArgument: S, searchProperty: string) {
        this.subs.sink = this.textSearchControl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    //this.filteredArticles = [];
                    this.tb_loading = true;

                }),
                map(value => {
                    if (value?.lenth == 0) value = undefined;
                    this.tableArgs = initalArgument;
                    this.tableArgs[searchProperty] = value;
                    return value;
                }),
                switchMap(value => this._baseService.sendPagingRequest(url, this.tableArgs)
                    .pipe(
                        finalize(() => {
                            this.tb_loading = false
                        }),
                    )
                )
            )
            .subscribe(res => this.onPagingTableDataRecieved(res));
    }
}
// export interface IBasePagingTableComponent {
//     onTableLazyLoad(event: LazyLoadEvent): void;
// }


export class AppInjector {

    private static injector: Injector;

    static setInjector(injector: Injector) {
        AppInjector.injector = injector;
    }

    static getInjector(): Injector {
        return AppInjector.injector;
    }
}
export interface IBaseEntity {
    id: number;
}