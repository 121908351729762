<div id="comment_box" class="brd_btm">

    <div class="comment_form">
        <p class="t_color_3" [innerHTML]="'GUIDES.commentbox' | translate">
        </p>
        <form [formGroup]="formSubmit" (ngSubmit)="onSave()">
            <div class="form-group">
                <textarea class="form-control" formControlName="message"
                    [ngClass]="{ 'is-invalid': submited && f.message.errors }"
                    [placeholder]="'COMMON.whatdoyouthink' | translate" rows="4"></textarea>
                <div *ngIf="submited && f.message.errors?.required" class="invalid-feedback">
                    <div> {{'COMMON.requiredfield' | translate}}</div>
                </div>
                <div *ngIf="submited && f.message.errors?.minlength" class="invalid-feedback">
                    <div>{{'COMMON.commenttooshort' | translate}}</div>
                </div>
            </div>
            <div class="form-group bottom">
                <div class="row" *ngIf="!currentUser">
                    <div class="col-xl-5">
                        <input type="text" [ngClass]="{ 'is-invalid': submited && f.fullName.errors }"
                            [placeholder]="'**'+('COMMON.fullname' | translate)" class="form-control"
                            formControlName="fullName">
                        <div *ngIf="submited && f.fullName.errors?.required" class="invalid-feedback">
                            <div>{{'COMMON.requiredfield' | translate}}
                            </div>
                        </div>
                        <div *ngIf="submited && f.fullName.errors?.minLength" class="invalid-feedback">
                            <div>{{'COMMON.requiredfield' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-xl-7">
                        <input type="email" placeholder="*Email" class="form-control" formControlName="email"
                            [ngClass]="{ 'is-invalid': submited && f.email.errors }" [email]="true">
                        <div *ngIf="submited && f.email.errors?.required" class="invalid-feedback">
                            <div>{{'COMMON.requiredfield' | translate}}
                            </div>
                        </div>
                        <div *ngIf="submited && f.email.errors?.email" class="invalid-feedback">
                            <div>{{'COMMON.invalidemail' | translate}}</div>
                        </div>
                    </div>
                </div>
                <button class="btn_color_3" style="margin-left:auto" type="submit">{{'COMMON.sendcomment' |
                    translate}}</button>
            </div>
        </form>
    </div>
</div>
<div id="comments" #myScrollContainer>
    <div class="comment_single brd_btm" *ngFor="let cmt of comments">
        <div class="row">
            <div class="col-lg-9">
                <div class="thumbnail_box">
                    <div class="img circle_img">
                        <img [lazyLoad]="rootAvatar+'/'+cmt.userId+'.jpg'"
                            [defaultImage]="'/assets/images/default_avatar.png'">

                    </div>
                    <div class="comment_detail">
                        <div class="author">
                            <a class="author_name t_bold">{{cmt.fullName}}</a>
                            <span class="time t_color_3"> - {{cmt.addedOnDate | timeAgo}}</span>
                        </div>
                        <div *ngIf="currentUser?.userType==4 && cmt.userId==-1" class="hint">
                            <span class="badge badge-warning">{{'COMMON.guest' | translate}}</span> {{cmt.email}}
                        </div>
                        <div *ngIf="currentUser?.userType==4 && cmt.userId!=-1" class="hint">
                            <span class="badge badge-primary">{{'COMMON.member' | translate}}</span>
                        </div>
                        <div class="text t_color_3">
                            {{cmt.commentContent}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="comment_info t_color_gray">
                    <div class="date">{{cmt.addedOnDate | date:'dd/MM/yyyy'}}</div>
                    <div class="reply" *ngIf="isDeleteAllow(cmt)">
                        <a (click)="deleteComment(cmt)">{{'COMMON.delete' | translate}}</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div id="last_comment"></div>
    <div *ngIf="isLoading">{{'COMMON.loadingcomment' | translate}} ...</div>
    <button *ngIf="loadMoreDisplay&&!isLoading" (click)="loadComments()" id="load_more_btn">{{'COMMON.loadmorecomment' |
        translate}}
        ({{totalComments-comments.length}})</button>
</div>