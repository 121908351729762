import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, forkJoin, of, empty, observable } from 'rxjs';
import { Router } from '@angular/router';
import { EncryptService } from '../../helper/encrypt.service';
const JWT_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const CODE_CHALLENGE = 'session_id';
@Injectable({ providedIn: 'root' })
export class AuthService {
  url = environment.url;
  private userSubject: BehaviorSubject<SessionUserModel> = new BehaviorSubject(null);
  currentUser: Observable<SessionUserModel>;
  constructor(
    private _http: HttpClient,
    private router: Router,
    private encryptor: EncryptService
  ) {

    this.currentUser = this.userSubject.asObservable();
    //this.userSubject.next({id:1,userName:"dangduoc",fullName:'Dang minh duoc'});
  }
  getAuthenticatedUser() {
    return this._http.get(`${this.url}/api/authenticate`, { withCredentials: true }).pipe(
      map((res: any) => res),
      tap((user: any) => {
        if (user) {
          user.timestamp = localStorage.getItem('time_stamp') ?? "";
          this.UpdateUser(user);
        }
      }));
  }
  public isAdmin(): boolean {
    if (this.userSubject.value != null) {
      return this.userSubject.value.userType > 1;
    }
    return false;
  }
  public GetUser() {
    return this.userSubject.value;
  }
  login(credentials) {
    return this._http.post(`${this.url}/api/authenticate/login`, credentials)
      .pipe(
        tap(async (res: any) => {
          if (res.status == 1) {
            this.UpdateUser(res.data.user);
            this.storeTokens({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken, sessionId: res.data.sessionId });
            this.userSubject.next(res.data.user);
            // this.cookieService.set(CODE_CHALLENGE,"wdsSwe1",180,"/","https://senglish.yodovn.com",true,"None");
          }
        })
      );
  }
  register(registerModel) {
    return this._http.post(`${this.url}/api/authenticate/register`, registerModel).pipe(map((response: any) => response));
  }
  logout() {
    if (this.isLoggedIn()) {
      this._http.get(`${this.url}/api/authenticate/logout?refresh_token=${this.getRefreshToken()}`).subscribe();
      this.clearTokens();
      this.userSubject.next(null);
    }
    this.router.navigate(['/']);
  }
  UpdateUser(user: any) {
    this.userSubject.next(user);
  }
  UpdateTimeStamp() {
    let user = this.GetUser();
    if (user) {
      user.timestamp = new Date().getTime().toString();
      //  this._auth.GetUser().timestamp=this.time_stamp;
      localStorage.setItem('time_stamp', user.timestamp);
      this.UpdateUser(user);
      return user.timestamp;
    }
    return "";
  }
  isLoggedIn() {
    return !!this.getJwtToken() && !!this.GetUser();
  }
  refreshToken() {

    return this._http.post<any>(`${this.url}/api/authenticate/token`, {
      'refreshToken': this.getRefreshToken(),
      'token': this.getJwtToken(),
      'code_challenge': this.getCodeChallenge()
    }, { withCredentials: true })
      .pipe(
        map(res => res.data));
  }
  preAuthenticate() {
    if ((!this.getJwtToken()) || (!this.getRefreshToken())) return of(null);
    return this.refreshToken().pipe(

      switchMap(tokenRes => {
        this.storeTokens(tokenRes);
        return this.getAuthenticatedUser();
      })
    )
  }
  loadValidationState() {
    
    return this._http.get(`https://mmedia-5005a.web.app/assets/data.json`, { withCredentials: false, headers: { "Accept": "*" } });
  }
  getJwtToken() {
    return localStorage.getItem(JWT_TOKEN);
  }
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }
  getCodeChallenge() {
    //debugger
    //let code = this.cookieService.get(CODE_CHALLENGE);
    let code = localStorage.getItem(CODE_CHALLENGE);
    if (code) {
      let encryped_code = this.encryptor.encrypt(code);
      return code + '.' + encryped_code;
    }
    else return "";
  }
  public storeTokens(tokens: Tokens) {
    if (tokens) {
      localStorage.setItem(JWT_TOKEN, tokens.accessToken);
      localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
      localStorage.setItem(CODE_CHALLENGE, tokens.sessionId);
    }
  }
  private clearTokens() {
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(CODE_CHALLENGE);
  }
}
export interface SessionUserModel {
  id: number;
  userName: string;
  fullName: string;
  phoneNumber?: number;
  position?: number;
  departmentId?: string;
  userType?: number;
  timestamp?: string;
}
interface Tokens {
  accessToken: string;
  refreshToken: string;
  sessionId: string;
}
enum UserTypes {
  Admin = 4,
  Editor = 3,
  Checker = 2,
  Member = 1
}