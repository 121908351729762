<!-- <app-main-layout></app-main-layout> -->
<div id="wrapper" *ngIf="!disabled">
    <app-nav></app-nav>
    <router-outlet></router-outlet>
    <!-- <app-partners-slideshow></app-partners-slideshow> -->
    <app-footer></app-footer>
</div>

<div *ngIf="!disabled" id="back_top"><img src="/assets/images/icons/arrow_up.png"></div>
<div class="not-available-wrapper" *ngIf="disabled">
    <h2>WEBSITE IS NOT AVAILABLE AT THIS TIME</h2>
</div>