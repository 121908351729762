import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  url = environment.url;
  constructor(private _http: HttpClient, @Inject(LOCALE_ID) protected localeId: string) {

  }
  GetDepartments() {
    return this._http.get(`${this.url}/api/settings/department`)
      .pipe(map((response: DepartmentModel[]) => {
        if (response) {
          return response;
        }
        else return null;
      }));
  }
  SendFeedBack(feedback: FeedBackModel) {
    return this._http.post(`${this.url}/api/feedback`, feedback);
  }
}
export interface DepartmentModel {
  id: number;
  name: string;
  address: string;
  phoneNumber: string;
  email: string;
  map: string;
}
export interface FeedBackModel {
  fullName: string;
  phoneNumber: string;
  email: string;
  title: string;
  message: string;
}