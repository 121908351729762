import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { AuthService, SessionUserModel } from 'src/app/services/auth/auth.service';
import { SubSink } from 'subsink';
import { AvatarRootUrl } from 'src/app/app.config';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NotificationEntityType, NotificationResponse, NotificationService } from 'src/app/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  private sub: SubSink = new SubSink();
  singed_in_user: SessionUserModel;
  rootAvatar: string = AvatarRootUrl;
  currentUrl: string;
  selectedSearchType = new FormControl();
  textSearch = new FormControl();
  searchTypeList = [
    {
      value: 1,
      label: "APP.project"
    },
    {
      value: 2,
      label: "NEWS.general"
    },
    {
      value: 3,
      label: "NEWS.specialist"
    }
  ]
  //lang: string = "";
  notifications: NotificationResponse[] = [];
  isNotificationClick: boolean = false;
  currentLang:string="vi";
  constructor(private dialog: MatDialog, private _auth: AuthService,
    private router: Router,
    private notificationService: NotificationService, public translate: TranslateService,
    public langService: LanguageService
  ) {
    this.selectedSearchType.setValue(1);
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    //this.lang = this.localeId == "en" ? "/en" : "";
    // if (this._auth.isLoggedIn)
    //   this.loadNotifcation();
    this.sub.sink = this._auth.currentUser.subscribe(res => {
      this.singed_in_user = res;
      //this.changListSearch();

    });
    this.sub.sink = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      this.currentUrl = res.url;
    });
    this.sub.sink = this.notificationService.getTotalUnseen().subscribe(res => {
      if (res.status == 1) {
        this.totalUnseenNotifications = res.data;
      }
    });
    
    this.currentLang=this.langService.currentLang;
  }
  // changListSearch() {
  //   if (this.singed_in_user) {
  //     if (this.searchTypeList.length == 3)
  //       // this.searchTypeList.push({
  //       //   value: 4,
  //       //   label: "Tin tức nội bộ"
  //       // });
  //   }
  //   else {
  //     this.searchTypeList = this.searchTypeList.filter(c => c.value < 4);
  //   }
  // }
  openLoginForm(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      panelClass: ['no_padding']
    });
    dialogRef.afterClosed().subscribe(res => {
      this.notifications = [];
      this.loadNotifcation();
    })
  }
  logOut() {
    this.notifications = [];
    this._auth.logout();
  }
  changeLanguage() {
    this.langService.switchLang();
    setTimeout(() => {
      this.translate.use(this.langService.currentLang);
      location.reload();
    }, 100);

    // const browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/vi|en/) ? browserLang : 'vi');

    // this.langService
    // let _lang = this.localeId == "en" ? "" : "/en";
    // let url = window.location.origin + _lang + window.location.pathname.replace('/en/', '/');
    // window.location.href = url;
  }
  alert() {
    alert('Chưa hoạt động');
  }
  isHoso() {
    if (!this.currentUrl) return false;
    return this.currentUrl.includes('/ho-so/');
  }
  isTintuc() {
    if (!this.currentUrl) return false;
    return this.currentUrl.includes('/tin-tuc');
  }
  onSearch() {
   // console.log(this.selectedSearchType.value);
    switch (parseInt(this.selectedSearchType.value)) {
      case 1: {
      
        this.router.navigate(['/du-an/grid'],{queryParams:{title:this.textSearch.value }})
     //   location.href = '/du-an/grid?title=' + this.textSearch.value;
        break;
      }
      case 2: {
        this.router.navigate(['/tin-tuc'],{queryParams:{title:this.textSearch.value }})
      //  this.router.navigate(['/du-an'])
        break;
      }
      case 3: {
        this.router.navigate(['/tin-tuc/chuyen-mon'],{queryParams:{title:this.textSearch.value }})
        //location.href = this.lang + '/tin-tuc/chuyen-mon?title=' + this.textSearch.value;
        break;
      }
      case 4: {
        // location.href = this.lang + '/tin-tuc/noi-bo?title=' + this.textSearch.value;
        break;
      }
    }
  }
  page: number = 1;
  pageNumber: number = 10;
  totalNotifications = 0;
  isShowLoadMoreNoti: boolean = false;
  totalUnseenNotifications = 0;
  loadNotifcation() {
    this.notificationService.getAll(this.page, this.pageNumber).subscribe(res => {
      if (res.status == 1) {
        this.totalNotifications = res.dataCount;
        this.notifications = [...this.notifications, ...res.data];
        if (res.data.length == 0) return;
        if (this.notifications.length < this.totalNotifications) {
          this.page += 1;
          this.isShowLoadMoreNoti = true;
        }
        else {
          this.isShowLoadMoreNoti = false;
        }
        // for (let index = 0; index < 50; index++) {
        //   this.notifications.push(this.notifications[0]);
        // }
      }
    })
  }
  onNotiClick(item: NotificationResponse) {
    console.log(item);
    if (!this.isNotificationClick) {
      this.isNotificationClick = true;
    }
    if (!item.status) this.sub.sink = this.notificationService.seen(item.id).subscribe();
    switch (item.entityType) {
      case NotificationEntityType.LikeNew:
      case NotificationEntityType.CommentNew:
      case NotificationEntityType.ConfirmNew:
        let _url = '/tin-tuc/' + (item.isPrivateNew ? 'noi-bo' : 'chuyen-mon') + '/chi-tiet';
        this.router.navigate([_url, item.entityId]);
        break;
      case NotificationEntityType.CreateNew:
        if (this.singed_in_user.userType > 1)
          this.router.navigate(['/quan-tri/quan-ly-bai-viet/phe-duyet-bai-viet', item.entityId]);
        else alert('Vai trò thành viên không thể đọc thông báo này');
        break;
      case NotificationEntityType.EditNew:
        if (this.singed_in_user.userType > 1)
          this.router.navigate(['/quan-tri/quan-ly-bai-viet/phe-duyet-bai-viet', item.entityId]);
        else alert('Vai trò thành viên không thể đọc thông báo này');
        break;
    }
  }
}
