<div id="partners_slider" class="horizontal-scrolling" *ngIf="loaded">
  <div class="animation">
    <div [class.first]="i==0" *ngFor="let slide of partners; let i=index" class="partner_logo">

      <a [href]="slide.link" title="{{slide.name}}">
        <img class="logo" [src]="root_image + slide.logo" alt="{{slide.name}}">
        <img class="logo_active" [src]="root_image + slide.logoActive" alt="{{slide.name}}">
      </a>
    </div>
  </div>
</div>