
<div class="thumbnail_card brd_btm" *ngIf="stat">
    <div class="cir_img">
        <img [lazyLoad]="rootAvatar+'/'+stat.id+'.jpg'"
        [defaultImage]="'/assets/images/default_avatar.jpg'">
    </div>
    <div>
        <div class="vertical_middle">
            <div class="author_name">
                {{stat.fullName}}
            </div>
            <div class="author_pos t_color_3">
                {{stat.position}}
            </div>
        </div>

    </div>
</div>
<div class="author_stat" *ngIf="stat">
    <div class="author_stat_line">
        <div class="t_color_gray">
            <span><img src="/assets/images/icons/calendar.svg"></span>
            <span>Ngày tham gia:</span>
        </div>
        <div>
            <span>{{stat.addedOnDate | date:'dd/MM/yyyy'}}</span>
        </div>
    </div>
    <div class="author_stat_line">
        <div class="t_color_gray">
            <span><img src="/assets/images/icons/catalog.svg"></span>
            <span>Số lương bài viết:</span>
        </div>
        <div>
            <span>{{stat.totalNewsWritten}} Bài</span>
        </div>
    </div>
    <div class="author_stat_line">
        <div class="t_color_gray">
            <span><img src="/assets/images/icons/view.svg"></span>
            <span>Số lương người xem:</span>
        </div>
        <div>
            <span>{{stat.totalViews}} Lượt</span>
        </div>
    </div>
    <div class="author_stat_line">
        <div class="t_color_gray">
            <span><img src="/assets/images/icons/comment.svg"></span>
            <span>Số lương tương tác:</span>
        </div>
        <div>
            <span>{{stat.totalInteractions}} Bình luận</span>
        </div>
    </div>

</div>
