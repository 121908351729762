<div class="grid grid_product_layout">
    <ngx-masonry>
        <!-- <div ngxMasonryItem class="masonry-item" *ngFor="let item of masonryItems">
            {{item.title}}
        </div> -->
        <a ngxMasonryItem class="block" *ngFor="let item of projects" [routerLink]="[ '/du-an/chi-tiet', item.id ]"
            [class.block1x1]="item.thumbnailRatio==1" [class.block1x2]="item.thumbnailRatio==2"
            [class.block2x1]="item.thumbnailRatio==3">

            <div class="behind-back" [lazyLoad]="image_data_root+'/'+item.id+'/'+item.thumbnail"
                [defaultImage]="'/assets/images/notfound.jpg'"></div>


            <div class="back back_project">
                <div class="sub-title">{{item.category}}</div>
                <div class="title-project">
                    <span [innerHTML]="item.title"></span>
                </div>
            </div>
        </a>
    </ngx-masonry>

</div>
<div *ngIf="projects?.length==0" class="empty">
    <img src="/assets/images/empty.png">
    <div>{{'COMMON.noresult' | translate}}</div>
    <a class="t_color_2" href="/du-an">[{{'COMMON.backtolist' | translate}}]</a>
</div>