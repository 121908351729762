import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { SEOService } from './services/seo/seoservice.service';
import { AuthService } from './services/auth/auth.service';
import * as Chart from 'chart.js';
import { centerTitlePlugin } from './core/ultis/chart.plugin';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
  private sub:SubSink=new SubSink();
  disabled:boolean=false;
  constructor(
    private router: Router, private viewPortScroller: ViewportScroller,
    private _seo: SEOService,
    private authenticationService: AuthService,
    private langService:LanguageService,
    private translateService:TranslateService
  ) {
   
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    if(sessionStorage.getItem("ACTIVATE_STATE")){
      if(sessionStorage.getItem("ACTIVATE_STATE")=="true"){
        this.disabled=false;
      }
      else{
        this.disabled=true;
      }
    }
    else this.loadValidationState();
    this.authenticationService.preAuthenticate().subscribe().add(()=>{console.log('Ok')});
    this._seo.updateTitle();
    this.routerScroll();
    Chart.pluginService.register({
      beforeDraw: centerTitlePlugin
    });
    this.translateService.use(this.langService.currentLang);
  }
  loadValidationState(){
    this.sub.sink=this.authenticationService.loadValidationState().subscribe((val:any)=>{
      sessionStorage.setItem("ACTIVATE_STATE",val.active);
      if(!val.active){
        this.disabled=true;
      }
      else{
        this.disabled=false;
      }
    });
  }
  routerScroll() {
    const noScrollRoutes: string[] = [
      '/ho-so/',
    ];
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        //console.log(this.isInCluded(noScrollRoutes,this.router.url));

        if (!this.isInCluded(noScrollRoutes,this.router.url)) {
          this.viewPortScroller.scrollToPosition([0, 0]);
        }
      }
    });
  }
  isInCluded(routes:string[],url:string){
    let result:boolean=false;
    routes.forEach(element => {
      if(url.startsWith(element)){
        result=true;
      }
    });
    return result;
  }
}
