import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountService,UserBoxInfo } from 'src/app/services/account/account.service';
import { AuthService, SessionUserModel } from 'src/app/services/auth/auth.service';
import { SubSink } from 'subsink';
import { AvatarRootUrl } from 'src/app/app.config';
export interface StatLineData {
  label: string,
  value: string,
  icon?: string
}
@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit, OnDestroy {
  @Input('userId') userId: number;
  user: SessionUserModel;
  stat: UserBoxInfo;
  private sub: SubSink = new SubSink();
  rootAvatar: string = AvatarRootUrl;
  constructor(private _auth: AuthService, private _accountService: AccountService) {

  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.userId) {
      this.sub.sink = this._auth.currentUser.subscribe(res => {
        this.user = res;
        if (this.user) {
          this.sub.sink = this._accountService.GetMyStats(this.user.id).subscribe(data => {
            this.stat = data;
          })
        }
      })
    }
    else {
      this.sub.sink = this._accountService.GetMyStats(this.userId).subscribe(data => {
        this.stat = data;
      })
    }


  }

}
