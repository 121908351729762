import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private langService:LanguageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     
        if (request.url.search("mmedia-5005a") != -1) {
            return next.handle(request);
        }
        if (!this.isSpecialRequest(request)) {
            if (!request.headers.has('Content-Type') && !request.headers.has('Custom-Content-Type')) {
                if (!this.isSpecialContentType(request)) {
                    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                }
            }
        }
       // console.log(this.langService.currentLang);
        request = request.clone({
            headers: request.headers.set('lang', this.langService.currentLang)
        });
        // add auth header with jwt if user is logged in and request is to the api url

        const isApiUrl = request.url.startsWith(environment.url);
        if (this.authenticationService.getJwtToken() && isApiUrl) {
            request = request.clone({
                setHeaders:
                {
                    Authorization: `Bearer ${this.authenticationService.getJwtToken()}`
                },
                withCredentials: true
            });
        }
        return next.handle(request);
    }

    private isSpecialRequest(req: any) {
        if (req.url.search("/api/authenticate/login") != -1) return true;
        
        return false;
    }
    private isSpecialContentType(req: any) {
        if (req.url.search("/upload") != -1) return true;
        if (req.url.search("/import") != -1) return true;
        return false;
    }
}