import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService, SessionUserModel } from 'src/app/services/auth/auth.service';
import { NewFeedbackModel, NewsService } from 'src/app/services/news/news.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-article-feedback-list',
  templateUrl: './article-feedback-list.component.html',
  styleUrls: ['./article-feedback-list.component.scss']
})
export class ArticleFeedbackListComponent implements OnInit,OnDestroy {
  @Input('feedbacks') feedbacks:NewFeedbackModel[];
  private sub:SubSink=new SubSink();
 currentUser:SessionUserModel;
  constructor(private _service:NewsService,private _auth:AuthService) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.currentUser=this._auth.GetUser();
  }

  delete(item: NewFeedbackModel){
    if(confirm("Bạn chắc chắn muốn xóa góp ý này chứ?")){
      this.sub.sink=this._service.DeleteFeedback(item.id).subscribe(res=>{
        if(res.status==1){
          this.feedbacks=this.feedbacks.filter(c=>c!=item);
        }
        else {
          alert('Xóa không thành công');
        }
      });
    }
  }
}
