import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LanguageService } from '../language.service';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public language: LanguageService,
  ) { }

  updateMetaInfo(description, type, image, url) {
    
    this.meta.updateTag({ name: 'description', content: description });
 
    this.meta.updateTag({ content: description,property:'og:description'} , 'property="og:description"' );
    if (type) this.meta.updateTag({ property: 'og:type', content: type },'property="og:type"');
    else this.meta.updateTag({ property: 'og:type', content: 'website' },'property="og:type"');
    if (image) this.meta.updateTag({ property: 'og:image', content: image },'property="og:image"');
    if (url) this.meta.updateTag({ property: 'og:url', content: url },'property="og:url"');
  }

  updateTitle(title?: string) {
   
    console.log(this.language.currentLang);
    let companyName= this.language.currentLang=='vi'?'Công ty Cổ phần Tư vấn và Dịch vụ Viettel':'Viettel Consultancy And Services Joint Stock Company';
    
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) { route = route.firstChild; }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
            let _tmpTitle="";
            if(event['title']){
              _tmpTitle += ' - '+event['title'];
            }
            this.titleService.setTitle(companyName + _tmpTitle);
            this.meta.updateTag({ name: 'og:title', content: _tmpTitle });
            if (event['description']){
              this.meta.updateTag({ name: 'description', content: event['description'] });
              this.meta.updateTag({ name: 'og:description', content: event['description'] });
            }
              
          });
    } else {
      this.titleService.setTitle(`${companyName} - ${title}`);
      this.meta.updateTag({ name: 'og:title', content: `${companyName} - ${title}`});
    }
  }
}
