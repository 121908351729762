import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private _currentLang: BehaviorSubject<string>;
    currentLangObs: Observable<string>;
    constructor() {
        let initLang = localStorage.getItem('lang') ?? 'vi';
        this._currentLang = new BehaviorSubject<string>(initLang);
        this._currentLang.asObservable();
    }
    updateLang(lang: 'vi' | 'en') {
        this._currentLang.next(lang);
    }
    get currentLang() {
        return this._currentLang.value;
    }
    switchLang() {
        if (this.currentLang == 'vi') this.updateLang('en');
        else this.updateLang('vi');
        localStorage.setItem('lang', this.currentLang);
    }

    get isVietnamese(){
        return this.currentLang=='vi';
    }

}