import { Pipe, PipeTransform } from "@angular/core";
import { MemberPointType, StatisticNewStatEnum, UserActivityType } from "src/app/services/statistic/statistic.service";
import { UserTypes } from "src/app/services/user/user.service";
@Pipe({
    name: 'newStatus'
})
export class newStatusPipe {
    transform(status: number) {
        switch (status) {
            case 0: return "Đợi phê duyệt";
            case 1: return "Đã phê duyệt";
            case 2: return "Góp ý điều chỉnh";
            default: return "";
        }
    }
}
@Pipe({
    name: 'userStatus'
})
export class userStatusPipe {
    transform(status: number) {
        switch (status) {
            case 0: return "Đã bị khóa";
            case 1: return "Đang hoạt động";
            case 2: return "Đã xóa";
            default: return "";
        }
    }
}
@Pipe({
    name: 'userType'
})
export class userTypePipe {
    transform(type: number) {
        let types = UserTypes;
        return types.filter(x => x.value == type)[0].label;
    }
}

@Pipe({
    name: 'memberPointType'
})
export class memberPointTypePipe {
    transform(type: MemberPointType) {
        if (type == MemberPointType.Actor) return "Điểm tác giả";
        else return "Điểm tương tác";
    }
}

@Pipe({
    name: 'userActivity'
})
export class userActivityTypePipe {
    transform(type: UserActivityType, shorten: boolean = false) {
        if (shorten) {
            if (type == UserActivityType.Like) return "lượt thích";
            if (type == UserActivityType.Comment) return "bình luận";
            if (type == UserActivityType.WritePost) return "bài viết";
        }
        else {
            if (type == UserActivityType.Like) return "lượt thích";
            if (type == UserActivityType.Comment) return "lượt bình luận";
            if (type == UserActivityType.WritePost) return "lượng bài viết";
        }

        return "";
    }
}

@Pipe({
    name: 'statisticNewStat'
})
export class StatisticNewStatPipe {
    transform(type: StatisticNewStatEnum) {
        if (type == StatisticNewStatEnum.Views) return "lượt xem";
        if (type == StatisticNewStatEnum.Comments) return "bình luận";
        if (type == StatisticNewStatEnum.News) return "bài viết";
        else return "";
    }
}
