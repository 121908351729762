import { environment } from '../environments/environment';
export const AvatarRootUrl: string = environment.url + '/upload/images/avatar';
export const DataImageUrl: string = environment.url + "/upload/images";
export const DataDocumentUrl: string = environment.url + "/upload/documents";
export enum UploadUrls {
    NEW,
    PROJECT,
    OPERATION_FIELD,
    ABOUT,
    PUBLIC_NEW,
    ACHIEVEMENT,
    PARTNER
}
export function getImageUploadUrl(type: UploadUrls) {

    switch (type) {
        case UploadUrls.NEW: {
            return DataImageUrl + "/new";
        }
        case UploadUrls.PUBLIC_NEW: {
            return DataImageUrl + "/public_new";
        }
        case UploadUrls.PROJECT: {
            return DataImageUrl + "/project";
        }
        case UploadUrls.OPERATION_FIELD: {
            return DataImageUrl + "/operation_field";
        }
        case UploadUrls.ABOUT:{
            return DataImageUrl+"/about";
        }
        case UploadUrls.ACHIEVEMENT: return DataImageUrl + "/about/achievement";
        case UploadUrls.PARTNER: return DataImageUrl + "/partner";
        default: {
            return DataImageUrl;
        }
    }
}
var new_category_types: any[] = [
    { label: 'Tin tức chuyên môn', value: 1 },
    {
        label: 'Tin tức nội bộ', value: 0
    }
]
export {new_category_types}