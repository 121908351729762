<div class="ck-content">
    <div class="feedback-item" *ngFor="let item of feedbacks">
        <div [innerHTML]="item.htmlContent | safeHtml"></div>
        
        <div class="actions">
            <span>- {{item.createdOnDate  | date:'dd/MM/yyyy'}}</span>
            <button class="btn btn-link t_color_2" (click)="delete(item)" *ngIf="currentUser&&currentUser.userType>=3">Xóa</button>
        </div>
    </div>  
</div>
