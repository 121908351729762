<div id="banner_project" class="brd_left brd_top">
    <div class="banner_slicker">
        <div class="banner_item" *ngFor="let item of fields">

            <img [lazyLoad]="image_data_root+'/'+item.banner">
          

        </div>
      
    </div>
    <div class="banner_info padding_left">
        <div id="slide_nav">
            <div id="slick_arrow"></div>
            <div class="paging t_color_3">
                <span class="current_">01</span>
                <span>/</span>
                <span class="total_">2</span>
            </div>
        </div>

        <div class="slide_info_box t_size">
            <div class="slide_info" *ngFor="let item of fields">
                <div class="img">
                    <img [lazyLoad]="image_data_root+'/'+item.thumbnail">
                </div>
                <div>
                    <div class="title t_bold">{{item.name}}</div>
                    <p class="description ck-content" [innerHTML]="item.description">
                     
                    </p>
                    <div class="view_more">
                        <a [routerLink]="['ho-so/linh-vuc-hoat-dong',item.id]" class="t_bold t_color_3">{{'COMMON.viewmore' | translate}}</a>
                    </div>
                </div>
              
            </div>
            


        </div>
    </div>


</div>