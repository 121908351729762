import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER, Injector } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './components/shared/main-layout/main-layout.component';
import { NavComponent } from './components/shared/nav/nav.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ContactComponent } from './components/contact/contact.component';
import { SharedModule } from './shared.module';
import { LoginDialogComponent } from './components/shared/dialogs/login-dialog/login-dialog.component';
// import localeVi from '@angular/common/locales/vi';
import { APP_BASE_HREF, CommonModule, PlatformLocation, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { appInitializer } from './helper/app.initializer';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { ErrorInterceptor } from './helper/error.interceptor';
import { AuthService } from './services/auth/auth.service';
import { AppPreloadingStrategy } from './app_preloading_strategy';
import { SwitchLanguageComponent } from './components/shared/switch-language/switch-language.component';
import { HomeOperationFieldsComponent } from './components/home/home-operation-fields/home-operation-fields.component';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { WrapParnerSlideLayoutComponent } from './components/shared/wrap-parner-slide-layout/wrap-parner-slide-layout.component';
import { AppInjector } from './core/components/base_component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// registerLocaleData(localeVi);
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    LoginDialogComponent,
    SwitchLanguageComponent,
    HomeOperationFieldsComponent,
    WrapParnerSlideLayoutComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    LazyLoadImageModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    TranslateModule.forRoot({
      defaultLanguage:'vi',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppPreloadingStrategy,
    //{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);// save a injector ref 💾
  }
}
