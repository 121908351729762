import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  private loader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loader: Observable<boolean>;
  constructor(public auth: AuthService, public router: Router) {
    this.loader = this.loader$.asObservable();
  }
  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isLoggedIn()) {
      let currentUser = this.auth.GetUser();
      if (currentUser) {
        // check if route is restricted by role
        if (_next.data.roles && _next.data.roles.indexOf(currentUser.userType) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['/']);
          return false;
        }

        // authorised so return true
        return true;
      }
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/']);
      return false;
    }
  }
}
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate();
    //return confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }
}
