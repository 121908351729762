import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  url = environment.url;
  constructor(private _http: HttpClient, @Inject(LOCALE_ID) protected localeId: string) {

  }
  GetAll() {
    return this._http.get(`${this.url}/api/settings/home`)
      .pipe(map((response: HomeSettingsResponse) => response));
  }
  GetPartners() {
    return this._http.get(`${this.url}/api/settings/partner`)
      .pipe(map((response: any) => response));
  }
}
export interface HomeSettingsResponse
{
    banners: HomeBannerProject[];
    projects: HomeListItemProject[];
    homeAboutArticle:string;
}
export interface HomeBannerProject
{
    id: number;
    title: string;
    location: string;
    summary: string;
    banner: string;
}
export interface HomeListItemProject
{
    id: number;
    title: string;
    category: string;
    thumbnail: string;
    thumbnailRatio:number;
}
export interface PartnerItemModel {
  id: number;
  logo: string;
  logoActive: string;
  name: string;
  link:string;
}