import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactService, DepartmentModel } from 'src/app/services/contact/contact.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit,OnDestroy {
  departments:DepartmentModel[];
  private sub:SubSink=new SubSink();
  constructor(private _service:ContactService) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.sink=this._service.GetDepartments().subscribe(res=>{
      this.departments=res;
    });
  }

}
