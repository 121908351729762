<div id="share_area" class="padding_right brd_top brd_btm">
    <div class="padding_left">
        <a href="#" class="btn_like">
            <img src="/assets/images/icon_like.svg">
            <span>Thích</span>
            <span class="number">10</span>
        </a>
        <a href="#" class="btn_share">
            <img src="/assets/images/icon_share.svg">
            <span>Chia sẻ</span>
            <span class="number">10</span>
        </a>
        <a href="#" class="btn_share float_right">
            <img src="/assets/images/black-gallon.svg">
            <span>Lưu lại</span>
        </a>
    </div>

</div>