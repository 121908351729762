<div id="top_header">
    <div class="row brd_btm">
        <div class="col-lg-3 brd_right">
            <a [routerLink]="['/']" id="logo" class="padding_left">
                <img src="/assets/images/logo.png">
            </a>
        </div>
        <div class="col-lg-6 brd_right">
            <div class="quick_access brd_btm t_sarabun">
                <a [routerLink]="['/ho-so/quan-he-co-dong']" [routerLinkActive]="['active']"
                    class="t_size">{{'APP.shareholderrelation' |translate}}</a>
                <a [routerLink]="['/others/tuyen-dung']" [routerLinkActive]="['active']"
                    class="t_size">{{'RECRUITMENT.title' |translate}}</a>
                <a [routerLink]="['/others/cau-hoi-thuong-gap']" [routerLinkActive]="['active']"
                    class="t_size">{{'FAQ.title' |translate}}</a>
                <a [routerLink]="['/others/gop-y']" [routerLinkActive]="['active']" *ngIf="singed_in_user"
                    class="t_size">{{'COMMON.feedback' | translate}}</a>
            </div>
            <div class="company_name" [class.en]="currentLang!='vi'">
                <h1>{{'APP.vietteljsc' |translate}}</h1>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="medal padding_right"><img src="/assets/images/menu/medal.png"></div>
        </div>
    </div>
    <div class="row brd_btm">
        <div class="col-lg-3 brd_right">
            <a [routerLink]="['/ho-so/thanh-tich/bang-chung-nhan']" class="iso padding_left">
                <img *ngIf="currentLang=='vi'" src="/assets/images/menu/iso.png">
                <img *ngIf="currentLang=='en'" src="/assets/images/menu/iso_en.png">
            </a>
        </div>
        <div class="col-lg-6 brd_right">
            <nav class="navbar navbar-expand-sm">
                <!-- Links -->
                <ul class="navbar-nav">
                    <li class="nav-item dropdown brd_right">
                        <a class="nav-link dropdown-toggle" [class.active]="isHoso()" data-toggle="dropdown">
                            {{'APP.introduction' | translate}}
                        </a>
                        <div class="dropdown-menu w75">
                            <a class="dropdown-item" [routerLink]="['/ho-so/ve-chung-toi']"
                                [routerLinkActive]="['active']"><span></span><span>{{'APP.aboutus' |
                                    translate}}</span></a>
                            <a class="dropdown-item" [routerLink]="['/ho-so/linh-vuc-hoat-dong']"
                                [routerLinkActive]="['active']"><span></span><span>{{'APP.business' |
                                    translate}}</span></a>
                            <a class="dropdown-item" [routerLink]="['/ho-so/quan-he-co-dong']"
                                [routerLinkActive]="['active']"><span></span><span>{{'APP.shareholderrelation'
                                    |translate}}</span></a>
                            <a class="dropdown-item" [routerLink]="['/ho-so/thanh-tich']"
                                [routerLinkActive]="['active']"><span></span><span>{{'APP.achievements' |
                                    translate}}</span></a>
                        </div>
                    </li>
                    <li class="nav-item brd_right">

                        <a class="nav-link" [routerLink]="['/du-an']" [routerLinkActive]="['active']">{{'APP.project' |
                            translate}}</a>
                    </li>
                    <li class="nav-item dropdown brd_right">

                        <a class="nav-link dropdown-toggle" data-toggle="dropdown"
                            [class.active]="isTintuc()">{{'APP.news' | translate}}</a>
                        <div class="dropdown-menu w50">
                            <a class="dropdown-item" [href]="'/tin-tuc'"><span></span><span>{{'NEWS.general' |
                                    translate}}</span></a>
                            <a class="dropdown-item"
                                [href]="'/tin-tuc/chuyen-mon'"><span></span><span>{{'NEWS.specialist' |
                                    translate}}</span></a>

                            <a class="dropdown-item" [href]="'/tin-tuc/noi-bo'" *ngIf="singed_in_user">
                                <span></span> <span>{{'NEWS.internal' | translate}}</span>
                            </a>

                        </div>
                    </li>

                    <li class="nav-item ">
                        <a class="nav-link" [routerLink]="['/lien-he']"
                            [routerLinkActive]="['active']">{{'APP.contact'|translate}}</a>
                    </li>


                </ul>
            </nav>
        </div>
        <div class="col-lg-3">
            <div class="actions_area padding_right">
                <div class="action dropdown search">
                    <a class="action_item" data-toggle="dropdown">
                        <svg class="svg_icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                            viewBox="0 0 55.747 56.063">
                            <g id="Seach" transform="translate(0.25 0.25)">
                                <path id="Path_15" data-name="Path 15"
                                    d="M54.091,39.885,49.713,35.5A24.966,24.966,0,0,0,51.787,25.36,25.232,25.232,0,1,0,26.671,50.721a24.159,24.159,0,0,0,10.139-2.075l4.378,4.38a8.777,8.777,0,0,0,5.991,2.536A9.617,9.617,0,0,0,53.86,52.8c3.456-3.919,3.687-9.453.23-12.911ZM26.671,46.11A20.657,20.657,0,0,1,5.933,25.361a20.738,20.738,0,1,1,41.476,0A20.658,20.658,0,0,1,26.671,46.11Zm23.964,3.228a4.389,4.389,0,0,1-6.221.231L40.957,46.11a24.545,24.545,0,0,0,6.452-6.455l3.456,3.458A4.395,4.395,0,0,1,50.634,49.338Z"
                                    transform="translate(-1.324 0)" fill="#77787b" stroke="#fff" stroke-width="0.5" />
                                <path id="Path_16" data-name="Path 16"
                                    d="M114.1,86.925A17.672,17.672,0,0,0,92.133,99.187a2.268,2.268,0,0,0,1.788,3.065h.766a3.076,3.076,0,0,0,2.554-1.788,13.052,13.052,0,0,1,6.131-7.663,12.852,12.852,0,0,1,9.707-1.022,2.425,2.425,0,0,0,3.065-1.788,2.586,2.586,0,0,0-2.043-3.065Z"
                                    transform="translate(-82.903 -77.298)" fill="#77787b" stroke="#fff"
                                    stroke-width="0.5" />
                            </g>
                        </svg>

                    </a>
                    <div class="dropdown-menu">
                        <form (submit)="onSearch()">
                            <div><input [formControl]="textSearch" name="text"
                                    placeholder="{{'COMMON.keywords' | translate}}" type="text">
                            </div>
                            <div>
                                <span>{{'COMMON.searchby' |translate}}</span>
                                <select [formControl]="selectedSearchType" name="type">
                                    <option [value]="item.value" *ngFor="let item of searchTypeList">{{item.label |
                                        translate}}
                                    </option>

                                </select>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="action dropdown notification">
                    <a class="action_item" data-toggle="dropdown" (click)="loadNotifcation()">
                        <svg class="svg_icon" xmlns="http://www.w3.org/2000/svg" width="15" height="16"
                            viewBox="0 0 58.054 63.15">
                            <g data-name="Thong bao" transform="translate(1 1)">
                                <path id="Path_11" data-name="Path 11"
                                    d="M365.941,41.811a2.548,2.548,0,0,1-2.548-2.548,26.584,26.584,0,0,0-7.835-18.918,2.547,2.547,0,1,1,3.6-3.6,31.639,31.639,0,0,1,9.328,22.521A2.548,2.548,0,0,1,365.941,41.811Zm0,0"
                                    transform="translate(-312.435 -14.085)" fill="none" stroke="#77787b"
                                    stroke-width="2" />
                                <path id="Path_12" data-name="Path 12"
                                    d="M2.548,41.811A2.549,2.549,0,0,1,0,39.263,31.641,31.641,0,0,1,9.328,16.742a2.547,2.547,0,0,1,3.6,3.6A26.579,26.579,0,0,0,5.1,39.263a2.549,2.549,0,0,1-2.548,2.548Zm0,0"
                                    transform="translate(0 -14.085)" fill="none" stroke="#77787b" stroke-width="2" />
                                <path id="Path_13" data-name="Path 13"
                                    d="M70.708,43.093a17.066,17.066,0,0,1-6.062-13.058v-7.1A17.85,17.85,0,0,0,49.359,5.3V2.548a2.548,2.548,0,0,0-5.1,0V5.3A17.848,17.848,0,0,0,28.976,22.931v7.1a17.085,17.085,0,0,1-6.084,13.078,4.458,4.458,0,0,0,2.9,7.845h42.04a4.459,4.459,0,0,0,2.877-7.865Zm0,0"
                                    transform="translate(-18.785 0)" fill="none" stroke="#77787b" stroke-width="2" />
                                <path id="Path_14" data-name="Path 14"
                                    d="M165.65,455.644A9.57,9.57,0,0,0,175.011,448H156.289A9.57,9.57,0,0,0,165.65,455.644Zm0,0"
                                    transform="translate(-137.623 -394.494)" fill="none" stroke="#77787b"
                                    stroke-width="2" />
                            </g>
                        </svg>
                        <span *ngIf="!isNotificationClick&&totalUnseenNotifications>0"
                            class="badge badge-danger">{{totalUnseenNotifications}}</span>
                    </a>
                    <div class="dropdown-menu">
                        <div class="noti-header"><span>Thông báo của bạn</span></div>
                        <div class="wrap_noti">
                            <a class="noti" [class.seen]="item.status" *ngFor="let item of notifications"
                                (click)="onNotiClick(item)">
                                <div class="avatar">
                                    <img [lazyLoad]="rootAvatar+'/'+item.actorId+'.jpg'"
                                        [defaultImage]="'/assets/images/default_avatar.jpg'" alt="Ảnh đại diện">
                                </div>
                                <div class="content">
                                    <div class="actor">
                                        <a>{{item.actor}} </a> &nbsp;
                                        <span> - {{item.dateCreated | timeAgo}}</span>
                                    </div>
                                    <div class="text">
                                        {{item.actionType}} <span>[{{item.entityName}}]</span> tại chuyên mục
                                        <span>[{{item.isPrivateNew?"TIN NỘI BỘ":"TIN CHUYÊN MÔN"}}]</span>
                                    </div>
                                </div>
                            </a>
                            <a class="load_more" (click)="loadNotifcation()" *ngIf="isShowLoadMoreNoti">Tải thêm</a>
                        </div>
                    </div>
                </div>
                <div class="action" *ngIf="!singed_in_user">
                    <a (click)="openLoginForm()" class="action_item">
                        <svg class="svg_icon" xmlns="http://www.w3.org/2000/svg" width="15" height="16"
                            viewBox="0 0 56.53 66.459">
                            <g id="Group_1" data-name="Group 1">
                                <path id="Path_9" data-name="Path 9"
                                    d="M151.2,35.81h.44a12.7,12.7,0,0,0,9.7-4.2c5.3-5.973,4.418-16.212,4.321-17.189-.344-7.335-3.812-10.845-6.675-12.483A15.3,15.3,0,0,0,151.59,0h-.234a15.325,15.325,0,0,0-7.4,1.885c-2.89,1.638-6.413,5.147-6.757,12.538-.1.977-.977,11.216,4.321,17.189A12.649,12.649,0,0,0,151.2,35.81ZM140.869,14.767c0-.041.014-.083.014-.11.454-9.868,7.459-10.927,10.46-10.927h.165c3.716.083,10.033,1.6,10.46,10.927a.268.268,0,0,0,.014.11c.014.1.977,9.455-3.4,14.382a9.021,9.021,0,0,1-7.088,2.945h-.138a8.991,8.991,0,0,1-7.074-2.945C139.919,24.25,140.855,14.85,140.869,14.767Z"
                                    transform="translate(-123.18)" fill="#77787b" />
                                <path id="Path_10" data-name="Path 10"
                                    d="M92.6,276.359v-.041c0-.11-.014-.22-.014-.344-.083-2.725-.261-9.1-6.234-11.134-.041-.014-.1-.027-.138-.041a39.711,39.711,0,0,1-11.423-5.2,1.857,1.857,0,1,0-2.133,3.042,42.865,42.865,0,0,0,12.565,5.739c3.207,1.142,3.564,4.569,3.661,7.707a2.761,2.761,0,0,0,.014.344,24.985,24.985,0,0,1-.289,4.253c-2.23,1.266-10.969,5.643-24.263,5.643-13.24,0-22.034-4.39-24.277-5.656a23.647,23.647,0,0,1-.289-4.253c0-.11.014-.22.014-.344.1-3.138.454-6.565,3.661-7.707a43.261,43.261,0,0,0,12.565-5.739,1.857,1.857,0,1,0-2.133-3.041,39.28,39.28,0,0,1-11.423,5.2c-.055.014-.1.028-.138.041-5.973,2.051-6.152,8.423-6.234,11.134a2.764,2.764,0,0,1-.014.344v.041c-.014.716-.028,4.39.7,6.234a1.766,1.766,0,0,0,.716.867c.413.275,10.308,6.579,26.864,6.579s26.452-6.317,26.864-6.579a1.842,1.842,0,0,0,.716-.867A22.141,22.141,0,0,0,92.6,276.359Z"
                                    transform="translate(-36.073 -223.566)" fill="#77787b" />
                            </g>
                        </svg>

                    </a>
                </div>
                <div class="action dropdown" *ngIf="singed_in_user">
                    <a *ngIf="singed_in_user" data-toggle="dropdown" class="action_item">
                        <img [lazyLoad]="rootAvatar+'/'+singed_in_user.id+'.jpg?t='+singed_in_user.timestamp"
                            [defaultImage]="'/assets/images/default_avatar.jpg'" alt="Ảnh đại diện">
                    </a>
                    <div class="dropdown-menu">
                        <a href="/tai-khoan" class="dropdown-item">
                            {{'COMMON.accountmanagement' | translate}}
                        </a>
                        <a class="dropdown-item" *ngIf="singed_in_user.userType>1" href="/quan-tri">
                            {{'COMMON.adminmanagement' | translate}}</a>
                        <a class="dropdown-item" (click)="logOut()">{{'COMMON.logout' | translate}}</a>
                    </div>
                </div>
                <div class="action">
                    <a (click)="changeLanguage()" class="action_item">
                        <img src="/assets/images/vietnam.svg" alt="vietnamese" *ngIf="langService.currentLang=='vi'">
                        <img src="/assets/images/united-kingdom.svg" alt="english"
                            *ngIf="langService.currentLang=='en'">
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>