import { Pipe,PipeTransform } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser'

// use better logic for every content load
let loadJsURL = function (url: string) {
  
  let canJsLoad = function (url: string) {
    if (!url) return false;
    let scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      // *td
      // better with substring or pos, make work with //
      if (scripts[i].src == url) return false;
    }
    return true;
  }


  // Load js url
  let insertJsUrl = function (url: string) {
    var script = document.createElement('script');
    script.setAttribute('src', url);
    document.body.appendChild(script);
  }
  insertJsUrl(url);
  //if (canJsLoad(url)) {
  //  insertJsUrl(url)
  //}
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustUrl(value);
  }
}
@Pipe({ name: 'safeResourceUrl' })
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
@Pipe({ name: 'checkScripts' })
export class CheckScriptsPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }
  transform(value: string) {
    let jsUrls = value.match('<script.*?src="(.*?)"');
    if (jsUrls) {
      loadJsURL(jsUrls[1]);
    }
    return value;
  }
}

@Pipe({
  name: 'oembed'
})
export class OembedPipe implements PipeTransform {
  transform(htmlContent: any): any {
    const oembed = htmlContent.split('</oembed>');
    let body = '';
    oembed.forEach((item, index) => {
      body += oembed[index] + '</oembed>';
      const oembed1 = item.split('url="')[1];
      if (oembed1) {
        const oembed2 = oembed1.split('">')[0];
        if (oembed2) {
          const youtube = oembed2.split('https://www.youtube.com/watch?v=')[1];
          if (youtube) {
            body += '<div class="iframe-container"><iframe src="https://youtube.com/embed/' + youtube + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
          }
        }
      }
    });
    return body;
  }
}