import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AvatarRootUrl } from 'src/app/app.config';
import { AuthService, SessionUserModel } from 'src/app/services/auth/auth.service';
import { CommentListItem, CommentService, CommentTargetTypeEnum, CreateCommentCommand, GetAllCommentQuery } from 'src/app/services/comment/comment.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-user-comment-box',
  templateUrl: './user-comment-box.component.html',
  styleUrls: ['./user-comment-box.component.scss']
})
export class UserCommentBoxComponent implements OnInit, OnDestroy {
  query: GetAllCommentQuery;
  comments: CommentListItem[] = [];
  formSubmit: FormGroup;
  submited: boolean;
  private sub: SubSink = new SubSink();
  @Input("targetId") targetId: number;
  @Input("targetType") targetType: CommentTargetTypeEnum;
  currentUser: SessionUserModel;
  totalComments: number;
  loadMoreDisplay: boolean;
  isLoading: boolean;
  rootAvatar: string = AvatarRootUrl;
  constructor(private _service: CommentService, private formBuilder: FormBuilder, private _auth: AuthService) {
    this.formSubmit = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required]],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  get f() { return this.formSubmit.controls; }
  ngOnInit(): void {

    this.sub.sink = this._auth.currentUser.subscribe(res => {
      this.currentUser = res;
    })
    this.query = {
      page: 1,
      pageSize: 10,
      targetId: this.targetId,
      targetType: this.targetType
    };
    this.loadComments();
  }
  loadComments() {
    this.isLoading = true;
    this.sub.sink = this._service.GetAllComment(this.query).subscribe(res => {
      if (res.status == 1) {
        this.comments = [...this.comments, ...res.data];
        this.totalComments = res.dataCount;
        if (this.totalComments > this.comments.length) {
          this.query.page += 1;
          this.loadMoreDisplay = true;
        }
        else {
          this.loadMoreDisplay = false;
        }
      }
      else {
        this.loadMoreDisplay = false;
        alert('Tải bình luận không thành công');
      }
    }).add(() => this.isLoading = false);
  }
  onSave() {
    this.submited = true;
    if(this.formSubmit.controls.message.invalid) return;
    if (this.formSubmit.invalid && !this.currentUser) return;
    
   
    let createModel: CreateCommentCommand = {
      fullName: this.f["fullName"].value,
      email: this.f["email"].value,
      commentContent: this.f["message"].value,
      targetType: this.targetType,
      targetId: this.targetId
    }
    if (this.currentUser) {
      createModel.fullName = this.currentUser.fullName;
      createModel.email = null;
    }
    this.sub.sink = this._service.AddComment(createModel).subscribe(res => {
      if (res.status == 1) {
        this.resetForm();
        this.comments.push(res.data);
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);

      }
      else {
        alert('Đăng bình luận không thành công');
      }
    })
  }
  resetForm() {
    this.formSubmit.reset();
    this.submited = false;

  }
  isDeleteAllow(comment: CommentListItem) {
    if (!this.currentUser) return false;
    return this.currentUser.id == comment.userId || this.currentUser.userType >= 2;
  }
  public scrollToBottom() {
    document.getElementById('last_comment').scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }
  deleteComment(item: CommentListItem) {
    if (!this.isDeleteAllow(item)) return;
    if (confirm("Bạn chắc chắn muốn xóa bình luận này chứ?")) {
      this.sub.sink = this._service.DeleteComment(item.id).subscribe(res => {
        if (res.status == 1) {
          this.comments = this.comments.filter(c => c != item);
          this.totalComments -= 1;
        }
        else {
          alert("Xóa không thành công");
        }
      },
        error => {
          alert("Xóa không thành công");
        }
      )
    }

  }
}
