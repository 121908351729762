import {  Component, Input, OnInit } from '@angular/core';
import { getImageUploadUrl, UploadUrls } from 'src/app/app.config';
import { ProjectListItem } from 'src/app/services/project/project.service';
@Component({
  selector: 'app-list-projects-grid',
  templateUrl: './list-projects-grid.component.html',
  styleUrls: ['./list-projects-grid.component.scss']
})
export class ListProjectsGridComponent implements OnInit {
  @Input('projects') projects: ProjectListItem[];
  @Input('total') total: number;
  image_data_root: string = getImageUploadUrl(UploadUrls.PROJECT);
  constructor() { }



  ngOnInit(): void {


  }
  getClass(type: number) {
    switch (type) {
      case 1: return 'block1x1';
      case 2: return 'block1x2';
      case 3: return 'block2x1';
    }
  }

}
