import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { SubSink } from 'subsink';
interface MenuItem {
  icon?: string,
  label: string,
  routerLink: string
}
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ROUTE_DATA_PAGETITLE = 'title';
  readonly home: MenuItem = { icon: 'pi pi-home', routerLink: '/', label: 'Trang chủ' };
  public BreadcrumbItems: MenuItem[];
  private sub: SubSink = new SubSink();
  title: string;
  locale:string="";
  @Input('hide_title') hide_title?:boolean;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,private langService:LanguageService) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    if(this.langService.currentLang=='en') this.locale='_en';
    this.buildBreadcrumb();
  }
  buildBreadcrumb() {
    this.sub.sink = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          return this.getPageTitle();
        }),
      )
      .subscribe((page_title: string | null) => {
        this.title = page_title;
        this.BreadcrumbItems = this.createBreadcrumbs(this.activatedRoute.root);
      });
    this.title = this.getPageTitle();
    if (!this.title) this.title = this.activatedRoute.snapshot.data[BreadcrumbComponent.ROUTE_DATA_PAGETITLE+this.locale];
   // this.title=this.activatedRoute.snapshot.data["title"];
    this.BreadcrumbItems = this.createBreadcrumbs(this.activatedRoute.root);
  }
  getPageTitle(): string | null {

    let child = this.activatedRoute.firstChild;
   // if (!this.title) this.title = this.activatedRoute.data[BreadcrumbComponent.ROUTE_DATA_PAGETITLE];
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_PAGETITLE+this.locale]) {
        return child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_PAGETITLE+this.locale];
      } else {
        return null;
      }
    }
    return null;
  }
  private createBreadcrumbs(route: ActivatedRoute, routerLink: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        routerLink += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB+this.locale];

      if (label) {
        breadcrumbs.push({ label: label, routerLink: routerLink });
      }


      return this.createBreadcrumbs(child, routerLink, breadcrumbs);
    }
  }
}
