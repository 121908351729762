<div class="wrapper">
    <a href="#" *ngIf="data.link">
        <img [src]="data.image">
    </a>
    <div *ngIf="!data.link" class="image_wrapper">
        <img [src]="data.image">
    </div>
    <div class="bottom" *ngIf="data.link">
        <a (click)="close()">Tôi đã rõ không hiển thị thông báo này nữa</a>
        <a>Để xem chi tiết <span class="t_bold">[Click]</span> bảng thông báo</a>
    </div>
</div>